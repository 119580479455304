import React, { useEffect, useState } from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    solid
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { sprintf } from "sprintf-js";
import CommonTable,
{ Column } from "../../commonTable";
import UnderlineHeading from "../../underlineHeading";
import Constants,
{
    invoiceStatus
} from "../../../constants";
import {
    AppDispatch,
    disableIdleLockSwitch,
    dismissNotice,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../globals";

import { styles } from "./style";
import { UserState } from "../../../redux/reducers/sessionReducer";
import HttpManager from "../../../services/HttpManager";
import { getErrorMessage } from "../../../common";
import CustomIcon from "../../customIcon";

export interface CancelInvoiceProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * The student data to show.
     */
    student: any;
    /**
     * Content to render inside the card.
     */
    invoice: any;
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
    /**
     * 
     * @function on success cancel invoice
     */
    onSuccess: () => void
}

interface CancelInvoiceFormData {
    notes: string;
    refundedTotal: number;
    invoiceItems: { id: number; refund: number }[];
}

const CancelInvoiceModalComponent: React.FC<CancelInvoiceProps> = ({
    student,
    invoice,
    closeModal,
    onSuccess
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const http = HttpManager.getInstance();
    const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<CancelInvoiceFormData>();

    const [refundValues, setRefundValues] = useState<{ [key: string]: number }>({});
    const [refundItems, setRefundItems] = useState<Set<number>>(new Set());
    const [refundData, setRefundData] = useState<{ id: number; refund: number; type: string }[]>([]);
    const [totalRefund, setTotalRefund] = useState<number>(0);

    useEffect(() => {
        if (invoice) {
            const initialRefundData = invoice.invoiceItems.map((item: any) => ({
                id: item.id,
                refund: 0,
                type: item.PRODUCT_TYPE
            }));

            setRefundData(initialRefundData);
        }
    }, [invoice]);

    useEffect(() => {
        const total = refundData.reduce((acc, refund) => acc + refund.refund, 0);
        setTotalRefund(total);
    }, [refundData]);

    const handleRefundChange = (data: any, value: number) => {
        let validatedValue = value;
        const paidAmount = invoice.invoiceItems.length > 1 
            ? data.subTotal.value : invoice.paidAmount; 

        if (validatedValue < 0) {
            validatedValue = 0;
        }

        if (validatedValue > paidAmount) {
            validatedValue = paidAmount;
        }

        setRefundValues(prevValues => ({
            ...prevValues,
            [data.id]: validatedValue
        }));

        setRefundData(prevData => prevData.map(refund =>
            refund.id === data.id
                ? { ...refund, refund: validatedValue }
                : refund
        ));
    }

    const toggleRefundSelection = (item: any) => {
        setRefundItems(prevItems => {
            const updatedItems = new Set(prevItems);

            if (updatedItems.has(item.id)) {
                updatedItems.delete(item.id);
                setRefundData(prevData =>
                    prevData.map(refund =>
                        refund.id === item.id
                            ? { ...refund, refund: 0 }
                            : refund
                    )
                );

                setRefundValues(prevValues => ({
                    ...prevValues,
                    [item.id]: 0
                }));
            } else {
                updatedItems.add(item.id);
            }

            return updatedItems;
        });
    }

    const conceptsColumns: Column[] = [
        {
            id: "selectItem", header: "", accessor: "",
            render: () =>
                <button className="btn btn-default" type="button"
                    disabled={false}
                >
                    <CustomIcon name="bCheck" />
                </button>,
            overrideCellStyle: () => ({
                borderBottom: 0,
                whiteSpace: "inherit",
                width: "30px",
                padding: "0",
                margin: "0"
            })
        },
        {
            id: "", header: Constants.Common.detail, accessor: "",
            render: (data: any) =>
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}>
                    <label style={{
                        wordBreak: "break-all",
                        whiteSpace: "unset",
                        position: "relative",
                    }}>
                        <strong style={{
                            color: (data.statusId === invoiceStatus.PARTLY_PAID ? "#C32722" : "#000000")
                        }}>
                            {data.PRODUCT_TYPE === "TUITION" ? data.monthNumber : data.name}
                        </strong>
                    </label>
                    <label style={{
                        width: "100%",
                        position: "relative",
                        wordBreak: "break-all",
                        whiteSpace: "unset"
                    }}>
                         {data.PRODUCT_TYPE === "TUITION" ? data.name : `SKU: ${data.sku}`}
                    </label>
                </div>,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "left",
                whiteSpace: "inherit",
                width: "100px"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left"
            })
        },
        {
            id: "", header: Constants.Common.units, accessor: "",
            render: (data: any) => data?.quantity ?? 1,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "center"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "center"
            })
        },
        {
            id: "", header: Constants.Common.price, accessor: "",
            render: (data: any) => data.price.moneyFormat,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "right"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left"
            })
        },
        {
            id: "", header: Constants.Common.totalText, accessor: "",
            render: (data: any) => data.subTotal.moneyFormat,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "right"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left",
                width: "100px"
            })
        },
        {
            id: "", header: Constants.Common.reimbursement, accessor: "",
            render: (data: any) =>
                Array.from(refundItems).includes(data.id) ?
                    <input
                        id={data.id}
                        min={0}
                        value={refundValues[data.id] || 0}
                        type="number"
                        disabled={
                            !Array.from(refundItems).includes(data.id)
                            || idleLocked
                        }
                        max={data.subTotal.vaule}
                        onChange={(event: any) => {
                            handleRefundChange(data, parseFloat(event.target.value) || 0)
                        }
                        }
                        style={{
                            border: Array.from(refundItems).includes(data.id) ? "2px solid #ccc" : "",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "right",
                            borderRadius: "4px",
                            padding: "2px",
                        }}
                    /> : <>{`${data.currencyCode}${sprintf("%.02f", refundValues[data.id] || 0)}`}</>,
            overrideCellStyle: () => ({
                ...styles.cellStyle,
                textAlign: "right",
                width: "100px"
            }),
            overrideHeaderStyle: () => ({
                ...styles.subtitle,
                textAlign: "left",
                width: "50px"
            })
        }
    ];

    const invoiceItemsColumns = conceptsColumns.map((column: any) => {
        if (column.id === "selectItem" && column.render) {
            return {
                ...column,
                render: (item: any) => (
                    <button
                        className="btn btn-default"
                        type="button"
                        disabled={idleLocked}
                        onClick={() => toggleRefundSelection(item)}
                    >
                        <CustomIcon
                            name={Array.from(refundItems).includes(item.id) ? "bCheck" : "bUncheck"} />
                    </button>
                ),
            };
        }
        return column;
    });

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{
                color: "#FF0000",
                display: "inline-block"
            }}>
                Error
            </h3>
        }))
    }

    const cancelInvoice = async (data: CancelInvoiceFormData) => {
        dispatch(dismissNotice());
        dispatch(enableIdleLockSwitch());
        try {
            const payload = { ...data };
            const response = await http.invoiceService.cancelInvoice(
                userData?.token ?? "",
                currentCampus.id,
                student.id,
                invoice.id,
                payload
            );
            reset();
            onSuccess();
        } catch (error) {
            onError(getErrorMessage(error));
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const confirmCancelOperation = (data: CancelInvoiceFormData) => {
        data = {
            ...data,
            refundedTotal: totalRefund,
            invoiceItems: refundData
        }
        dispatch(displayNotice({
            cornerClose: true,
            message: `Esta acción es irreversible, 
            ¿Desea CANCELAR la factura ${invoice.id} 
            y reembolsar un total de ${currentCampus.currency}${totalRefund} ?`,
            heading:
                <h3 style={{
                    color: "#0000FF",
                    display: "inline-block"
                }}>
                    Confirme
                </h3>,
            procedure: () => { cancelInvoice(data) }
        }));
    }

    return (
        invoice && student &&
        <React.Fragment>
            <div style={{
                display: "flex",
                alignItems: "stretch"
            }}>
                <h2 style={{ flexGrow: 1 }}>
                    <span style={{
                        ...styles.subtitleContainer,
                        paddingLeft: "10px"
                    }}>
                        {Constants.Common.invoice}&nbsp;
                        {invoice.id}
                    </span>
                </h2>
                <button style={{
                    ...styles.btnCloseModal,
                    flexGrow: 0,
                    zIndex: 2
                }}
                    onClick={closeModal}
                >
                    <FontAwesomeIcon style={{
                        height: "100%",
                        position: "relative",
                        bottom: "1px"
                    }}
                        icon={solid("times")} />
                </button>
            </div>
            <div style={{
                display: "flex",
                alignItems: "stretch",
                flexWrap: "wrap"
            }}>
                <div style={{
                    display: "inline-flex",
                    alignItems: "baseline"
                }}>
                    <label style={{
                        ...styles.smallGrayText,
                        padding: "0px 5px",
                        position: "initial"
                    }}>
                        {Constants.Common.status}
                    </label>
                    <span style={{ ...styles.boldText }}>
                        {invoice.status}
                    </span>
                </div>
                <div style={{
                    display: "inline-flex",
                    alignItems: "baseline"
                }}>
                    <label style={{
                        ...styles.smallGrayText,
                        padding: "0px 5px",
                        position: "initial"
                    }}>
                        {Constants.Common.dueDate}
                    </label>
                    <div>
                        {invoice.invoiceDate}
                    </div>
                </div>
                <div style={{
                    display: "inline-flex",
                    alignItems: "baseline"
                }}>
                    <label style={{
                        ...styles.smallGrayText,
                        padding: "0px 5px",
                        position: "initial"
                    }}>
                        {Constants.Common.due}
                    </label>
                    <div>
                        {invoice.dueDate}
                    </div>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%"
            }}>
                <UnderlineHeading name={"Alumno"} />
                <div className="row">
                    <div className="col-sm-6">
                        <div className="inputContainer">
                            <label style={{
                                ...styles.smallGrayText,
                                position: "initial",
                                textAlign: "left",
                                marginLeft: "0px"
                            }}>
                                {Constants.Common.names}
                            </label>
                            <div style={{ borderBottom: "2px solid #8F91DA" }}>
                                {student.firstName}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="inputContainer">
                            <label style={{
                                ...styles.smallGrayText,
                                position: "initial",
                                textAlign: "left",
                                marginLeft: "0px"
                            }}>
                                {Constants.Common.lastNames}
                            </label>
                            <div style={{ borderBottom: "2px solid #8F91DA" }}>
                                {student.lastName}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit(confirmCancelOperation)}
                className="form">
                {
                    invoice && invoice?.invoiceItems.length > 0
                        ?
                        <div style={{ width: "100%" }}>
                            <CommonTable
                                className="not-hover"
                                columns={invoiceItemsColumns}
                                data={invoice.invoiceItems}
                            />
                        </div>
                        : <></>
                }
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "20px"
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center"
                    }}>
                        <label style={{
                            ...styles.smallGrayText,
                            paddingRight: "15px",
                            position: "relative"
                        }}>
                            {Constants.Common.totalText}
                        </label>
                        <h3 style={{ ...styles.subtitleContainer }}>
                            {invoice.currencyCode}&nbsp;
                            {sprintf("%.02f", (invoice.total))}
                        </h3>
                    </div>
                </div>
                <div style={{
                    padding: "10px"
                }}>
                    <label htmlFor="notes"
                        style={{
                            position: "relative"
                        }}>
                        {Constants.Common.notes}
                    </label>
                    <div style={{
                        wordBreak: "break-all",
                        textOverflow: "unset"
                    }}>
                        <textarea id="notes"
                            {
                            ...register('notes',
                                {
                                    required: 'El campo notas es requerido.',
                                    minLength: {
                                        value: 10,
                                        message: 'La nota debe tener al menos 10 caracteres'
                                    }
                                }
                            )}
                            placeholder={Constants.Common.notes}
                            name="notes"
                            style={{
                                resize: "vertical",
                                width: "100%",
                                border: "1px solid #7678BF",
                                borderRadius: "5px",
                                minHeight: "150px",
                                padding: "5px"
                            }}
                        />
                        {errors.notes &&
                            <p style={{
                                ...styles.errorMessage
                            }}>
                                {errors.notes.message}
                            </p>
                        }
                    </div>
                </div>
                <div style={{
                    textAlign: "center"
                }}>
                    <button
                        className="btn btn-outline-danger rounded-pill"
                        type="submit"
                        disabled={idleLocked}
                        style={{
                            width: "50%"
                        }}>
                        {Constants.Common.cancel}
                    </button>
                </div>
            </form>
        </React.Fragment >
    );
}

export default CancelInvoiceModalComponent;