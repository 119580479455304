import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { default as SelectComponent } from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { FILTER_TYPES } from "../../constants";
import { FilterType } from "../../types";
import "./style.css";

export interface FilterOption {
	id: string;
	label: string;
	value: string;
}

export interface Column {
	/**
	 * Id to identify the Column
	 */
	id: string;
	/**
	 * Label to show in the Column
	 */
	header: string;
	/**
	 * Object Property to access data
	 */
	accessor: string;
	/**
	 * Optional render ?, can be HTMLElements such as Buttons, Checkboxes, etc.
	 */
	render?: (data: any) => React.ReactNode;
	/**
	 * Optional render ?, can be HTMLElements such as Buttons, Checkboxes, etc.
	 */
	renderSubRow?: (data: any) => React.ReactNode;
	/**
	 * Optional function to apply custom styles
	 */
	overrideCellStyle?: (data: any) => React.CSSProperties;
	/**
	 * Optional function to apply custom styles in table headers
	 */
	overrideHeaderStyle?: (data: any) => React.CSSProperties;
	/**
	 * Type of filter: 'dropdown', 'input', etc.
	 */
	filterType?: FilterType;

	/**
	 * Array of filter options for dropdowns
	 */
	filterOptions?: FilterOption[];
}

export interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
	/**
	 * Columns to show in the Table
	 */
	columns: Column[];
	/**
	 * Data to show in the Table
	 */
	data: any[];
	/**
	 * Optional function for row click event
	 */
	onRowClick?: (data: any) => void;
	/**
	 * Filters to apply in the Table columns
	 */
	filters?: any;
	/**
	 * Optional function search data
	 */
	onFilterChange?: () => void;
	/**
	 * Optional flag to clear filters
	 */
	onClearFilters?: () => void;
}

/**
 * Common Table component for user interaction
 */
const CommonTable: React.FC<TableProps> = ({
	className,
	columns,
	data,
	onRowClick,
	filters,
	onFilterChange = () => { }
}) => {

	const tableClass = classNames("tableComponent", className);

	const renderFilterType = (
		filterType: FilterType,
		column: Column,
		filters: any,
		onFilterChange: (columnId: string, value: any) => void,
	) => {
		switch (filterType) {
			case FILTER_TYPES.INPUT: // Input text filter
				return (
					<input
						type="text"
						value={filters[column.id] || ""}
						onChange={(e) => onFilterChange(column.id, e.target.value)}
						placeholder={`Filter by ${column.header}`}
					/>
				);
			case FILTER_TYPES.DROPDOWN: // Dropdown filter
				return (
					column.filterOptions && (
						<SelectComponent
							id={column.id}
							options={column.filterOptions}
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.value}
							onChange={(selectedOption) => {
								const value = selectedOption ? selectedOption.value : "";
								onFilterChange(column.id, value);
							}}
							styles={{
								menu: (provided) => ({
									...provided,
									width: 'auto',
									minWidth: '150px'
								}),
								// control: (provided) => ({
								// 	...provided,
								// 	minWidth: '250px'
								// })
							}}
							placeholder={`Select ${column.header}`}
						/>
					)
				);
			case FILTER_TYPES.MULTISELECT: // Input text multiselect
				return (
					column.filterOptions && (
						<Multiselect
							options={column.filterOptions}
							displayValue="label"
							emptyRecordMsg="No hay más opciones"
							onSelect={(selectedOptions) => {
								const selectedValues = selectedOptions.map((option: any) => option.value);
								onFilterChange(column.id, selectedValues);
							}}
							onRemove={(selectedOptions) => {
								const selectedValues = selectedOptions.map((option: any) => option.value);
								onFilterChange(column.id, selectedValues);
							}}
							selectedValues={filters[column.id]?.map((value: any) =>
								column.filterOptions?.find(option => option.value === value)
							) || []}
						/>
					)
				);
			default: // No filter
				return null;
		}
	}

	return (
		<div className="table-wrapper">
			<table className={tableClass}>
				<thead>
					<tr key="commonRow"
						style={{ color: "#8F91D8" }}>
						{columns.map((column, index) => {
							const headerStyle = column.overrideHeaderStyle ? column.overrideHeaderStyle(data) : {};
							return (
								<th
									key={index}
									style={{
										borderBottom: column.header === "" ? 0 : "1px solid #C7C7C7",
										...headerStyle
									}}>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignContent: "space-around",
										}}>
										{renderFilterType(
											column.filterType ?? 0,
											column,
											filters,
											onFilterChange
										)}
										{column.header}
									</div>
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{data.map((rowData, rowIndex) => (
						<React.Fragment key={`rowFragment${rowIndex}`}>
							<tr
								key={`commonTableRow${rowIndex}`}
								onClick={() => onRowClick && onRowClick(rowData)}
								style={{ cursor: onRowClick ? 'pointer' : 'default' }}
							>
								{columns.map((column, colIndex) => {
									const cellStyle = column.overrideCellStyle ? column.overrideCellStyle(rowData) : {};
									return (
										<td
											style={{ textAlign: "left", whiteSpace: "nowrap", ...cellStyle }}
											key={colIndex}
										>
											{column.render ? column.render(rowData) : rowData[column.accessor]}
										</td>
									);
								})}
							</tr>
							{/* Render if column has renderSubRow property*/}
							{columns.map((column, colIndex) =>
								column.renderSubRow && column.renderSubRow(rowData) ? (
									<tr key={`subRow${rowIndex}-${colIndex}`}>
										<td colSpan={columns.length} style={{ padding: 0, border: 0 }}>
											<div style={{ padding: "8px" }}>
												{column.renderSubRow(rowData)}
											</div>
										</td>
									</tr>
								) : null
							)}
						</React.Fragment>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default CommonTable;
