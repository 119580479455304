import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { sprintf } from "sprintf-js";

import Constants,
{
    paymentStatus,
} from "../../../../constants";

import { styles } from "./style";
import {
    AppDispatch,
    disableIdleLockSwitch,
    dismissNotice,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../../globals";
import { UserState } from "../../../../redux/reducers/sessionReducer";
import { getErrorMessage } from "../../../../common";
import { useForm } from "react-hook-form";
import HttpManager from "../../../../services/HttpManager";

export interface PaymentDetailProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * The student data to show.
     */
    student: any;
    /**
     * Payment data to show.
     */
    payment: any;
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
    /**
     * 
     * @function to close Modal
     */
    onSuccess: () => void
}

interface CancelPaymentFormData {
    notes: string;
}

const CancelPaymentModalComponent: React.FC<PaymentDetailProps> = ({
    student,
    payment,
    closeModal,
    onSuccess
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const http = HttpManager.getInstance();
    const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const currencies: any = useSelector((state: RootState) => state.rootReducer.catalog.currencies || []);
    const [currency, setCurrency] = useState<any>({});
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<CancelPaymentFormData>();

    const cancelPayment = async (data: CancelPaymentFormData) => {
        dispatch(dismissNotice());
        dispatch(enableIdleLockSwitch());
        try {
            const payload = { ...data };
            const response = await http.paymentService.cancelPaymentById(
                userData?.token ?? "",
                currentCampus.id,
                payment.id,
                payload
            );
            reset();
            onSuccess();
        } catch (error) {
            onError(getErrorMessage(error));
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const confirmCancelOperation = (data: CancelPaymentFormData) => {
        dispatch(displayNotice({
            cornerClose: true,
            message: `Esta acción es irreversible, ¿Desea cancelar un pago de ${payment.currencyCode}${payment.amount}?`,
            heading:
                <h3 style={{
                    color: "#0000FF",
                    display: "inline-block"
                }}>
                    Confirme
                </h3>,
            procedure: () => { cancelPayment(data) }
        }));
    }

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                Error
            </h3>
        }))
    }

    useEffect(() => {
        const newCurrency = currencies.find((currency: any) => currency.id === payment.currencyId) || {};
        setCurrency(newCurrency);
    }, [currencies]);

    return (
        student &&
        <React.Fragment>
            <div style={{
                display: "flex",
                alignItems: "stretch"
            }}>
                <h2 style={{
                    flexGrow: 1
                }}>
                    <span style={{
                        paddingLeft: "10px"
                    }}>
                        {Constants.Common.invoicePayment} {payment.invoiceId}
                    </span>
                </h2>
                <button style={{
                    ...styles.btnCloseModal,
                    flexGrow: 0,
                    zIndex: 2
                }}
                    onClick={closeModal}
                >
                    <FontAwesomeIcon
                        icon={solid("times")}
                        style={{
                            height: "100%",
                            position: "relative",
                            bottom: "1px"
                        }}
                    />
                </button>
            </div>
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                gap: "10px",
                padding: "10px"
            }}>
                <div
                    style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <label htmlFor="name"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.student}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {student.firstName}
                        &nbsp;
                        {student.lastName}
                    </div>
                </div>
                <div style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right"
                }}>
                    <label htmlFor="name"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Screens.Finances.modalPayments.payment.number}
                    </label>
                    <div style={{
                        borderBottom: "1px solid #7678BF"
                    }}>
                        {payment.id}
                    </div>
                </div>
            </div>

            <div style={{
                background: "#F1F1FF",
                padding: "15px"
            }}>
                <h5 style={{
                    display: "inline-block",
                    width: "70%",
                    textAlign: "center"
                }}>
                    {
                        `${Constants.Screens.Finances.modalPayments.payment.receive} (${currency.code}) `
                    }
                </h5>
                <div style={{
                    display: "inline-block",
                    width: "30%",
                    textAlign: "right",
                    borderBottom: "1px solid #7678BF"
                }}>
                    <h5 style={{
                        marginBottom: "unset"
                    }}>
                        {sprintf("%.02f", payment.amount)}
                    </h5>
                </div>
            </div>

            <form onSubmit={handleSubmit(confirmCancelOperation)} className="form">
                <div style={{
                    padding: "10px"
                }}>
                    <label htmlFor="notes"
                        style={{
                            position: "relative"
                        }}>
                        {Constants.Common.notes}
                    </label>
                    <div style={{
                        wordBreak: "break-all",
                        textOverflow: "unset"
                    }}>
                        <br />
                        <textarea id="notes"
                            {
                            ...register('notes',
                                {
                                    required: 'El campo notas es requerido.',
                                    minLength: {
                                        value: 10,
                                        message: 'La nota debe tener al menos 10 caracteres'
                                    }
                                }
                            )}
                            placeholder={Constants.Common.notes}
                            name="notes"
                            style={{
                                resize: "vertical",
                                width: "100%",
                                border: "1px solid #7678BF",
                                borderRadius: "5px",
                                minHeight: "150px",
                                padding: "5px"
                            }}
                        />
                        {errors.notes &&
                            <p style={{
                                ...styles.errorMessage
                            }}>
                                {errors.notes.message}
                            </p>
                        }
                    </div>
                </div>
                <div style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px"
                }}>
                    <button
                        className="btn btn-outline-danger rounded-pill"
                        type="submit"
                        disabled={
                            idleLocked
                            || payment.statusId === paymentStatus.CANCELLED
                        }
                        style={{
                            width: "50%",
                            cursor: payment.statusId === paymentStatus.CANCELLED ? "not-allowed" : "pointer"
                        }}>
                        {Constants.Common.cancel}
                    </button>
                    <button className="btn btn-outline-default rounded-pill"
                        onClick={closeModal}
                        disabled={idleLocked}
                        type="button"
                        style={{

                            width: "50%",
                            border: "1px solid #000000"
                        }}
                    >
                        {Constants.Common.close}
                    </button>
                </div>
            </form>
        </React.Fragment>
    );

}

export default CancelPaymentModalComponent;