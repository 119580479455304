import React,
{
	useEffect,
	useState,
	useRef
}
	from "react"
import {
	OverlayTrigger,
	Tooltip
}
	from "react-bootstrap"
import Box
	from "@mui/material/Box"
import { FontAwesomeIcon }
	from "@fortawesome/react-fontawesome"
import { usePDF }
	from "react-to-pdf"
import { jsPDF }
	from "jspdf"
import { Popover }
	from "react-tiny-popover"
import {
	solid,
	regular
}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {
	useSelector,
	useDispatch
}
	from "react-redux"
import FileSaver
	from "file-saver"
import { sprintf }
	from "sprintf-js"

import LeDatePicker
	from "./../../components/leDatePicker"
import CustomIcon
	from "./../../components/customIcon"
import UnderlineHeading
	from "./../../components/underlineHeading"
import { SelectReact }
	from "./../../components/select"
import {
	buttonClickHandling,
	isEmpty,
	monthRef,
	toAscendingDate,
	validateField,
}
	from "./../../common"
import InvoicesService
	from "./../../services/campus/invoices.service"
import PaymentsService
	from "./../../services/campus/payments.service"
import invoicesService
	from "./../../services/campus/invoices.service"

import Constants,
{
	invoiceStatus,
	owedTuitionType,
	paymentStatus
}
	from "../../constants"
import { styles }
	from "./style"
import {
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	dismissNotice
}
	from "./../../globals"

const FinancePrompt = (props: any) => {
	const
		{
			toggleOtherSelection, toggleKitSelection, otherRef, kitRef, articleRef, scheduleChargeRef, paymentMethodRef, payment,
			resetFn, promptDismissal, promptDisplayQueue, setPromptDisplayQueue, totalToPaid, student, scheduleChargeAddRef,
			conceptsCount, latestInvoices, invoiceStatusRef, paymentStatusRef, handleTypeOfDiscount, handleDiscount,
			toggleCourseChargeSelection, selectedInvoice, setSelectedInvoice, scheduleRef, defaultDate, recipientAccountRef,
			setSelectedPaymentMethod, availableAccounts
		} = props

	const basicInfo: any = useSelector((state: RootState) => state.basicInfo.value)
	const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value)
	const sessionToken: string | null = useSelector((state: RootState) => state.sessionToken.value)
	const currentUserCampus: any = useSelector((state: RootState) => state.currentUserCampus.value)
	const campusRef: any = useSelector((state: RootState) => state.campusRef.value)
	const currencySymbol: string = basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol
	const currencyCode: string = basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].code
	const [editableInvoice, setEditableInvoice] = useState<any>(null)
	const [selectedPayment, setSelectedPayment] = useState<any>(payment)
	const [editablePayment, setEditablePayment] = useState<any>(payment)
	const [noMethodProvided, setNoMethodProvided] = useState<boolean>(false)
	const [noAccountProvided, setNoAccountProvided] = useState<boolean>(false)
	const [amountIsZero, setAmountIsZero] = useState<boolean>(false)
	const [reimbursements, setReimbursements] = useState<any>({})
	const [chargesMap, setChargesMap] = useState<any>({})
	const [owedTuitions, setOwedTuitions] = useState<any>([])
	const [owedArticles, setOwedArticles] = useState<any>([])
	const [refundReason, setRefundReason] = useState<any>("")
	let invoiceFormRef = useRef<HTMLFormElement>(null)
	let paymentFormRef = useRef<HTMLFormElement>(null)
	let invoiceFileInputRef = useRef<HTMLInputElement>(null)
	let paymentFileInputRef = useRef<HTMLInputElement>(null)
	const paymentPrintableRef: any = useRef<HTMLDivElement>(null)
	const dispatch = useDispatch()
	const invoiceListing: string[] = ["owed_tuitions", "owed_articles"]
	const allowedFiletypes: string[] =
		[
			"image/png",
			"image/jpeg",
			"application/pdf",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
		]

	usePDF({ filename: "page.pdf" })
	/*
		Determine the status of an invoice based on its properties.
		@returns{JSX.Element} JSX element representing the status of the invoice.
	*/
	const handleStatusInvoiceById = (invoiceStatusId: any): JSX.Element => {
		// Determine the status based on the invoice properties article
		const status = invoiceStatusRef["IS_" + invoiceStatusId].name//return status from catalog
		// Return a JSX element representing the status
		return <span style={status === Constants.Common.expired ? styles.statusRedText : styles.statusText}>
			{status}
		</span>
	}

	const handleInvoicePdf = async () => {
		if (invoiceFormRef && invoiceFormRef.current && !invoiceFormRef.current.checkValidity()) {
			invoiceFormRef.current.reportValidity()
		}
		else {
			try {
				dispatch(enableIdleLockSwitch())

				const response: any = await invoicesService.invoicePDF
					(campusRef[currentUserCampus].id, student.id, selectedInvoice)

				const pdfUrl: any = window.URL.createObjectURL(new Blob([response.data]))// Create a URL object from the binary data received in the response
				const link: any = document.createElement("a")// Create a temporary anchor element to trigger the download
				link.href = pdfUrl

				link.setAttribute("download", "Factura" + selectedInvoice.id + ".pdf")// Set the download attribute with desired filename
				document.body.appendChild(link)
				link.click()// Simulate a click on the anchor element to trigger the download
				document.body.removeChild(link)// Cleanup : remove the temporary anchor element

				return true// Indicate successful download
			}
			catch (error) {
				procedureComplaint("Hubo un error al tratar de obtener el documento!")
			}
			finally {
				dispatch(disableIdleLockSwitch())
			}
		}
	}

	const confirmRemoval = () => {
		const totalAmount = Object.values(reimbursements).reduce((accumulator: number, current: any) => accumulator + current.amount, 0)

		dispatch
			(
				displayNotice
					({
						cornerClose: true,
						message: "Esta acción es irreversible, ¿Desea reembolsar un total de " + currencySymbol + " " + totalAmount + "?",
						heading: <h3 style={{ color: "#0000FF", display: "inline-block" }}>
							Confirme
						</h3>,
						procedure: async () => {
							const removalError: string = "El comando no pudo ser procesado"

							dispatch(enableIdleLockSwitch())
							dispatch(dismissNotice())

							try {
								const conceptListing: any =
								{
									"owedTuitions": owedTuitions,
									"owedArticles": owedArticles
								}

								const result = await InvoicesService.refundInvoice
									(campusRef[currentUserCampus].id, student.id, conceptListing, refundReason, selectedInvoice)

								if (result.status == 200 || result.status == 204) {
									dispatch
										(
											displayNotice
												({
													cornerClose: false,
													procedure: resetFn,
													message: "Información procesada con éxito",
													heading: <h3 style={{ color: "#00FF00", display: "inline-block" }}>
														Listo
													</h3>
												})
										)

									setReimbursements({})
									setChargesMap({})
									setOwedTuitions([])
									setOwedArticles([])
								}
								else {
									procedureComplaint(removalError)
								}
							}
							catch (error) {
								procedureComplaint(removalError)
							}
							finally {
								dispatch(disableIdleLockSwitch())
							}
						}
					})
			)
	}

	const calculateTotalItem = (concept: any) => {
		return concept.discountInPercentage
			? (concept.quantity * concept.price) - concept.discountAmount
			: (concept.quantity * concept.price) - concept.discountValue
	}
	/*
		Handles the reimbursement for a concept based on the provided event and concept data.
		@param{Event} event - The event object containing information about the reimbursement.
		@param{Object} concept - The concept data related to the reimbursement.
	*/
	const handleReimbursement = (event: any, concept: any) => {
		let reimbursement = event.target.name// Extract the reimbursement name from the event--------------------------------
		let amount = +event.target.value < 0 ? 0 :
			(+event.target.value > concept.invoice_item.total ? concept.invoice_item.total : +event.target.value)
		// Create an updated copy of the reimbursements object with the new amount
		let updatedReimbursements = { ...reimbursements, [reimbursement]: { ...reimbursements[reimbursement], amount: amount } }

		if (concept.invoice_item.owed_tuition_id) {
			const updatedTuitions = owedTuitions.map
				(
					(tuition: any) => {
						if (tuition.id === concept.invoice_item.owed_tuition_id) {
							return { ...tuition, refund: amount }// Update refund property-----------------------------------------
						}

						return tuition// Return the item unchanged if not the targeted id----------------------------------------
					}
				)

			setOwedTuitions(updatedTuitions)
		}
		else {
			const updatedArticles = owedArticles.map
				(
					(article: any) => {
						if (article.id === concept.invoice_item.owed_article_id) {
							return { ...article, refund: amount }
						}

						return article
					}
				)

			setOwedArticles(updatedArticles)
		}

		setReimbursements(updatedReimbursements)// Update the state with the modified reimbursements object
	}
	/*
		Toggles the reimbursement for a charge based on the provided charge data.
		If the reimbursement exists, it is removed; otherwise, it is added.
		@param{Object} charge - The charge data associated with the reimbursement.
		@param{number} index - The index of the charge within the invoice.
	*/
	const toggleReimbursement = (charge: any, index: number) => {
		let updatedReimbursements = { ...reimbursements }
		let reimbursementKey = "RE_" + charge.id

		if (reimbursements[reimbursementKey]) {
			if (charge.invoice_item.owed_tuition_id)//If the reimbursement exists, start the recursive removal process.---------
			{
				const removeIds: any = []
				let currentCharge: any = charge

				while (currentCharge) {
					removeIds.push(currentCharge.id)
					currentCharge = chargesMap[currentCharge.prevRef]
				}

				let removeItemById = owedTuitions.filter((tuition: any) => !removeIds.includes(tuition.id))

				setOwedTuitions(removeItemById)
				removePrevRefsRecursively(charge, index, updatedReimbursements)
			}
			else {
				let removeItemById = owedTuitions.filter((article: any) => article.id !== charge.invoice_item.owed_article_id)

				setOwedArticles(removeItemById)
			}
		}
		else {
			updatedReimbursements[reimbursementKey] = { id: charge.id, amount: 0 }//If it doesn't exist, add the reimbursement and enable the current charge if it has a prevRef.

			let item: any =
			{
				discount: charge.discount,
				refund: 0
			}

			if (charge.tax_id > 0) {
				item.taxId = charge.tax_id
				item.taxVal = charge.tax_value
			}

			if (charge.invoice_item.owed_tuition_id) {
				item.id = charge.invoice_item.owed_tuition_id

				setOwedTuitions([...owedTuitions, item])
			}
			else {
				item.id = charge.invoice_item.owed_article_id

				setOwedArticles([...owedArticles, item])
			}

			if (charge.prevRef) {
				enableCharge(index - 1)// Assuming charge.prevRef refers to the previous charge's index.
			}
		}

		setReimbursements(updatedReimbursements)//After processing, update the reimbursements state.
	}
	/*
		Recursively removes the reimbursement for a charge and its previous references.
		@param{Object} charge - The charge data associated with the reimbursement.
		@param{number} index - The index of the charge within the invoice.
		@param{Object} updatedReimbursements - The updated reimbursements object.
	*/
	const removePrevRefsRecursively = (charge: any, index: number, updatedReimbursements: any) => {
		if (!charge || index < 0) {
			return
		}
		// Remove the current reimbursement if it exists.--------------------------------------------------------------------
		const reimbursementKey = "RE_" + charge.id

		if (updatedReimbursements[reimbursementKey]) {
			delete updatedReimbursements[reimbursementKey]
			disableCharge(index - 1);
		}

		if (charge.prevRef && chargesMap[charge.prevRef])// Continue with the previous reference if it exists.
		{
			removePrevRefsRecursively(chargesMap[charge.prevRef], index - 1, updatedReimbursements);
		}
	}
	/*
		Disables a charge at the specified index by updating its disabled property to true.
		@param{number} index - The index of the charge within the invoice.
	*/
	const disableCharge = (index: number) => {
		if (index < 0 || index >= selectedInvoice.owed_tuitions.length) {
			return
		}

		let newState = { ...selectedInvoice }

		newState.owed_tuitions[index].disabled = true

		setSelectedInvoice(newState)
	}
	/*
		Enables a charge at the specified index by updating its disabled property to false.
		@param{number} index - The index of the charge within the invoice.
	*/
	const enableCharge = (index: number) => {
		if (index < 0 || index >= selectedInvoice.owed_tuitions.length) {
			return
		}

		let newState = { ...selectedInvoice }
		newState.owed_tuitions[index].disabled = false

		setSelectedInvoice(newState)
	}

	const handleInvoiceChange = (e: any) => {
		if (e && e.target) {
			setEditableInvoice({ ...editableInvoice, [e.target.name]: e.target.value })
		}
	}

	const handlePaymentChange = (e: any) => {
		if (e && e.target) {
			if (e.target.name === "method") {
				// editablePayment.account
				setSelectedPaymentMethod(e.target.value);
				setEditablePayment({ ...editablePayment, method: e.target.value, account: e.target.value.accounts })
			}
			else {
				setEditablePayment({ ...editablePayment, [e.target.name]: e.target.value })
			}
		}
	}

	const handleInvoiceItemNotes = (value: any) => {
		setRefundReason(value)
	}

	const mapAccount = (accountId: any) => {
		return availableAccounts["RA_" + accountId].label
	}

	const [newPayment, setNewPayment] = useState<any>
		({
			method: {},
			account: {},
			reference_number: "",
			notes: "",
			dateValue: new Date(defaultDate + " 23:00:00"),
			amount: 0,
			file_ref: {}
		})

	const promptWidth: any =
	{
		checkout: "648px",
		payment: "583px",
		invoice: "648px",
		cancelling: "648px"
	}

	const procedureComplaint = (message: string) => {
		dispatch
			(
				displayNotice
					({
						cornerClose: false,
						message: message,
						heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
							Error
						</h3>
					})
			)
	}

	const paymentPrompt = (fullPayment: boolean = false, noPayment: boolean = false) => {

		if (noPayment) {
			setPromptDisplayQueue(["generateInvoice", ...promptDisplayQueue])
			return;
		}
		const payment: any = (editableInvoice == null || editableInvoice.id == null)

			?
			{
				...newPayment, limit: +totalToPaid,
				amount: +totalToPaid,
				fullPaymentRequired: fullPayment,
			}
			:
			{
				...newPayment, limit: (+editableInvoice.total) - ((+editableInvoice.pendingPaidTotal) + (+editableInvoice.approvedPaidTotal)),
				amount: (+editableInvoice.total) - ((+editableInvoice.pendingPaidTotal) + (+editableInvoice.approvedPaidTotal)),
				fullPaymentRequired: fullPayment,
				invoice_id: editableInvoice.id,
			}

		setSelectedPayment(payment)
		setEditablePayment(payment)
		setPromptDisplayQueue(["payment", ...promptDisplayQueue])
	}

	const removeInvoiceAttachment = (filename: string) => {
		let invoice = { ...editableInvoice }

		delete editableInvoice.file_ref[filename]

		setEditableInvoice(invoice)
	}

	const removePaymentAttachment = (filename: string) => {
		let payment = { ...editablePayment }

		delete editablePayment.file_ref[filename]

		setEditablePayment(payment)
	}

	const onSelectPaymentAttachment = (event: any) => {
		if ((event && event.target) && (event.target.files && event.target.files.length > 0)) {
			if (event.target.files[0].size > 26214400) {
				procedureComplaint("Sólo se permiten archivos entre 1 y 25 Mb")

				if (paymentFileInputRef != null && paymentFileInputRef.current != null) {
					paymentFileInputRef.current.value = ""
				}
			}
			else {

				if (allowedFiletypes.indexOf(event.target.files[0].type) < 0) {
					procedureComplaint
						(
							"Sólo se permiten archivos de tipo jpeg, png, pdf, xlsx o docx." +
							"Quizá la extensión no corresponda al contenido. Verifique de ser posible."
						)

					if (paymentFileInputRef != null && paymentFileInputRef.current != null) {
						paymentFileInputRef.current.value = ""
					}
				}
				else {
					let filename: string = event.target.files[0].name

					if (editablePayment.file_ref[filename] != null) {
						procedureComplaint("Ya existe un archivo adjunto a este pago con ese nombre")
					}
					else {
						setEditablePayment
							({ ...editablePayment, file_ref: { ...editablePayment.file_ref, [filename]: event.target.files[0] } })

						if (paymentFileInputRef != null && paymentFileInputRef.current != null) {
							paymentFileInputRef.current.value = ""
						}
					}
				}
			}
		}
	}

	const onSelectInvoiceAttachment = (event: any) => {
		if ((event && event.target) && (event.target.files && event.target.files.length > 0)) {
			if (event.target.files[0].size > 26214400) {
				procedureComplaint("Sólo se permiten archivos entre 1 y 25 Mb")

				if (invoiceFileInputRef != null && invoiceFileInputRef.current != null) {
					invoiceFileInputRef.current.value = ""
				}
			}
			else {
				if (allowedFiletypes.indexOf(event.target.files[0].type) < 0) {
					procedureComplaint
						(
							"Sólo se permiten archivos de tipo jpeg, png, pdf, xlsx o docx." +
							"Quizá la extensión no corresponda al contenido. Verifique de ser posible."
						)

					if (invoiceFileInputRef != null && invoiceFileInputRef.current != null) {
						invoiceFileInputRef.current.value = ""
					}
				}
				else {
					let filename: string = event.target.files[0].name

					if (editableInvoice.file_ref[filename] != null) {
						procedureComplaint("Ya existe un archivo adjunto a este pago con ese nombre")
					}
					else {
						setEditableInvoice
							({ ...editableInvoice, file_ref: { ...editableInvoice.file_ref, [filename]: event.target.files[0] } })

						if (invoiceFileInputRef != null && invoiceFileInputRef.current != null) {
							invoiceFileInputRef.current.value = ""
						}
					}
				}
			}
		}
	}

	const showPaymentPrompt = (payment: any) => {
		setSelectedPayment({ ...payment })
		setEditablePayment({ ...payment })
		setPromptDisplayQueue(["payment", ...promptDisplayQueue])
	}

	const generateInvoice = async () => {
		if (paymentFormRef && paymentFormRef.current) {
			if (!paymentFormRef.current.checkValidity()) {
				paymentFormRef.current.reportValidity()
			}
			else {
				let concept: any
				let discount: any
				let taxValue: any
				let conceptListing: any = {}
				let revisionListing: any = {}
				const paymentProcessingFailure: string = "La factura no pudo generarse."

				if (editableInvoice && editableInvoice.id) {
					revisionListing.owedTuitions = editableInvoice.owed_tuitions
					revisionListing.owedArticles = editableInvoice.owed_articles
				}
				else {
					revisionListing.owedTuitions = Object.values(scheduleChargeRef)
					revisionListing.owedArticles = Object.values(articleRef)
				}

				for (let concepts of ["owedTuitions", "owedArticles"]) {
					if (revisionListing[concepts].length > 0) {
						conceptListing[concepts] = revisionListing[concepts].map
							(
								(item: any) => {
									concept = { id: item.id }

									if (item.discountValue != null && (!isNaN(item.discountValue) && +item.discountValue > 0)) {
										discount = sprintf("%.02f", +item.discountValue).slice(0, -1)

										if (discount.slice(-1) == "0") {
											discount = discount.slice(0, -1)
										}

										if (discount.slice(-1) == ".") {
											discount = discount.slice(0, -1)
										}

										discount = (item.discountInPercentage && Math.floor(discount) != discount)
											? discount : sprintf("%d", discount);

										concept.discount = discount + (item.discountInPercentage ? "%" : "")
									}

									if (item.tax_id > 0) {
										concept.taxId = +item.tax_id

										if (item.taxValue != null && (!isNaN(item.taxValue) && +item.taxValue > 0)) {
											taxValue = sprintf("%.02f", +item.taxValue).slice(0, -1)

											if (taxValue.slice(-1) == "0") {
												taxValue = taxValue.slice(0, -1)
											}

											if (taxValue.slice(-1) == ".") {
												taxValue = taxValue.slice(0, -1)
											}

											concept.taxVal = taxValue + (item.taxInPercentage ? "%" : "")
										}
									}

									return concept
								}
							)
					}
				}

				dispatch(enableIdleLockSwitch())

				try {
					const result: any = await PaymentsService
						.processPayment(campusRef[currentUserCampus].id, student.id, editablePayment, conceptListing, editableInvoice, true)

					if (result.status == 200 || result.status == 204) {
						dispatch(disableIdleLockSwitch())
						dispatch
							(
								displayNotice
									({
										cornerClose: false,
										procedure: resetFn,
										message: "Información procesada con éxito",
										heading: <h3 style={{ color: "#00FF00", display: "inline-block" }}>
											Listo
										</h3>
									})
							)
					}
					else {
						dispatch(disableIdleLockSwitch())
						procedureComplaint(paymentProcessingFailure)
					}
				}
				catch (error: any) {
					console.log(error)
					dispatch(disableIdleLockSwitch())
					procedureComplaint
						(
							error.response.status != 409 ? paymentProcessingFailure : "Hay conflictos en la información proporcionada " +
								"(Número de referencia). " +
								"Revise que los valores sean únicos en comparación con los registros existentes " +
								"(Pagos para todos planteles de este país)"
						)
				}
			}
		}
	}

	const processPayment = async () => {
		if (paymentFormRef && paymentFormRef.current) {
			if (!paymentFormRef.current.checkValidity()) {
				paymentFormRef.current.reportValidity()
			}
			else {
				if (editablePayment.method.id == null) {
					setNoMethodProvided(true)

					setTimeout(() => setNoMethodProvided(false), 3000)
				}
				else {
					if (editablePayment.account.id == null) {
						setNoAccountProvided(true)

						setTimeout(() => setNoAccountProvided(false), 3000)
					}
					else {
						let concept: any
						let discount: any
						let taxValue: any
						let conceptListing: any = {}
						let revisionListing: any = {}
						const paymentProcessingFailure: string = "El pago no pudo procesarse"

						if (editableInvoice && editableInvoice.id) {
							revisionListing.owedTuitions = editableInvoice.owed_tuitions
							revisionListing.owedArticles = editableInvoice.owed_articles
						}
						else {
							revisionListing.owedTuitions = Object.values(scheduleChargeRef)
							revisionListing.owedArticles = Object.values(articleRef)
						}

						for (let concepts of ["owedTuitions", "owedArticles"]) {
							if (revisionListing[concepts].length > 0) {
								conceptListing[concepts] = revisionListing[concepts].map
									(
										(item: any) => {
											concept = { id: item.id }

											if (item.discountValue != null && (!isNaN(item.discountValue) && +item.discountValue > 0)) {
												discount = sprintf("%.02f", +item.discountValue).slice(0, -1)

												if (discount.slice(-1) == "0") {
													discount = discount.slice(0, -1)
												}

												if (discount.slice(-1) == ".") {
													discount = discount.slice(0, -1)
												}

												discount = (item.discountInPercentage && Math.floor(discount) != discount)
													? discount : sprintf("%d", discount);

												concept.discount = discount + (item.discountInPercentage ? "%" : "")
											}

											if (item.tax_id > 0) {
												concept.taxId = +item.tax_id

												if (item.taxValue != null && (!isNaN(item.taxValue) && +item.taxValue > 0)) {
													taxValue = sprintf("%.02f", +item.taxValue).slice(0, -1)

													if (taxValue.slice(-1) == "0") {
														taxValue = taxValue.slice(0, -1)
													}

													if (taxValue.slice(-1) == ".") {
														taxValue = taxValue.slice(0, -1)
													}

													concept.taxVal = taxValue + (item.taxInPercentage ? "%" : "")
												}
											}

											return concept
										}
									)
							}
						}

						dispatch(enableIdleLockSwitch())

						try {
							const result: any = await PaymentsService.processPayment
								(campusRef[currentUserCampus].id, student.id, editablePayment, conceptListing, editableInvoice)

							if (result.status == 200 || result.status == 204) {
								dispatch(disableIdleLockSwitch())
								dispatch
									(
										displayNotice
											({
												cornerClose: false,
												procedure: resetFn,
												message: "Información procesada con éxito",
												heading: <h3 style={{ color: "#00FF00", display: "inline-block" }}>
													Listo
												</h3>
											})
									)
							}
							else {
								dispatch(disableIdleLockSwitch())
								procedureComplaint(paymentProcessingFailure)
							}
						}
						catch (error: any) {
							console.log(error)
							dispatch(disableIdleLockSwitch())
							procedureComplaint
								(
									error.response.status != 409 ? paymentProcessingFailure : "Hay conflictos en la información proporcionada " +
										"(Número de referencia). " +
										"Revise que los valores sean únicos en comparación con los registros existentes " +
										"(Pagos para todos planteles de este país)"
								)
						}
					}
				}
			}
		}
	}

	const removePayment = () => {
		dispatch
			(
				displayNotice
					({
						cornerClose: true,
						message: "¿Eliminar pago?",
						heading: <h3 style={{ color: "#0000FF", display: "inline-block" }}>
							Confirme
						</h3>,
						procedure: async () => {
							const conceptSet: string[] = ["owedTuitions", "owedArticles"]
							const conceptSetting: string[] = ["owed_tuitions", "owed_articles"]
							const removalError: string = "Los cambios no pudieron ser procesados"
							let conceptListing: any = {}
							let taxValue: string
							let discount: string
							let concept: any

							conceptSetting.map
								(
									(concepts: string, index: number) => {
										if (editableInvoice[concepts] && editableInvoice[concepts].length > 0) {
											conceptListing[conceptSet[index]] = editableInvoice[concepts].map
												(
													(item: any) => {
														concept = { id: item.id }

														if (item.discountValue != null && (!isNaN(item.discountValue) && +item.discountValue > 0)) {
															discount = sprintf("%.03f", +item.discountValue).slice(0, -1)

															if (discount.slice(-1) == "0") {
																discount = discount.slice(0, -1)
															}

															if (discount.slice(-1) == "0") {
																discount = discount.slice(0, -1)
															}

															if (discount.slice(-1) == ".") {
																discount = discount.slice(0, -1)
															}

															concept.discount = discount + (item.discountInPercentage ? "%" : "")
														}

														if (item.tax_id > 0) {
															concept.taxId = +item.tax_id

															if (item.taxValue != null && (!isNaN(item.taxValue) && +item.taxValue > 0)) {
																taxValue = sprintf("%.03f", +item.taxValue).slice(0, -1)

																if (taxValue.slice(-1) == "0") {
																	taxValue = taxValue.slice(0, -1)
																}

																if (taxValue.slice(-1) == "0") {
																	taxValue = taxValue.slice(0, -1)
																}

																if (taxValue.slice(-1) == ".") {
																	taxValue = taxValue.slice(0, -1)
																}

																concept.taxVal = taxValue + (item.taxInPercentage ? "%" : "")
															}
														}

														return concept
													}
												)
										}
									}
								)

							dispatch(enableIdleLockSwitch())
							dispatch(dismissNotice())

							try {
								const result = await PaymentsService.removePayment
									(
										campusRef[currentUserCampus].id, student.id, editablePayment, conceptListing,
										editableInvoice
									)

								if (result.status == 200 || result.status == 204) {
									dispatch
										(
											displayNotice
												({
													cornerClose: false,
													procedure: resetFn,
													message: "Información procesada con éxito",
													heading: <h3 style={{ color: "#00FF00", display: "inline-block" }}>
														Listo
													</h3>
												})
										)
								}
								else {
									dispatch(disableIdleLockSwitch())
									procedureComplaint(removalError)
								}
							}
							catch (error) {
								console.log(error)
								dispatch(disableIdleLockSwitch())
								procedureComplaint(removalError)
							}
						}
					})
			)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect(() => setEditableInvoice({ ...selectedInvoice }), [selectedInvoice])

	useEffect
		(
			() => {
				setSelectedPayment({ ...payment })
				setEditablePayment({ ...payment })
			},
			[payment]
		)
	//A React effect hook that runs after the component renders and whenever the 'selectedInvoice' state changes.
	//It creates a map of charges for efficient access by ID and updates the 'chargesMap' state accordingly.
	useEffect
		(
			() => {
				let newState: any = { ...selectedInvoice }//Create a copy of the 'selectedInvoice' state
				const chargesMap: any = {}//Create a map of charges for efficient access by ID

				if (selectedInvoice.hasOwnProperty("owed_tuitions")) {
					newState.owed_tuitions.forEach((tuition: any) => chargesMap[tuition.refIndex] = tuition)
				}

				setChargesMap(chargesMap)//Update the 'chargesMap' state with the newly created map
			},
			[selectedInvoice]
		)

	//-----------------------------------------------------------------------------------------------------------------------
	const updateInvoice = async () => {
		const conceptSet: string[] = ["owedTuitions", "owedArticles"]
		const conceptSetting: string[] = ["owed_tuitions", "owed_articles"]
		const updateError: string = "Los cambios no pudieron ser procesados"
		let conceptListing: any = {}
		let taxValue: string
		let discount: string
		let concept: any

		conceptSetting.map
			(
				(concepts: string, index: number) => {
					if (editableInvoice[concepts].length > 0) {
						conceptListing[conceptSet[index]] = editableInvoice[concepts].map
							(
								(item: any) => {
									concept = { id: item.id }

									if (item.discountValue != null && (!isNaN(item.discountValue) && +item.discountValue > 0)) {
										discount = sprintf("%.03f", +item.discountValue).slice(0, -1)

										if (discount.slice(-1) == "0") {
											discount = discount.slice(0, -1)
										}

										if (discount.slice(-1) == "0") {
											discount = discount.slice(0, -1)
										}

										if (discount.slice(-1) == ".") {
											discount = discount.slice(0, -1)
										}

										concept.discount = discount + (item.discountInPercentage ? "%" : "")
									}

									if (item.tax_id > 0) {
										concept.taxId = +item.tax_id

										if (item.taxValue != null && (!isNaN(item.taxValue) && +item.taxValue > 0)) {
											taxValue = sprintf("%.03f", +item.taxValue).slice(0, -1)

											if (taxValue.slice(-1) == "0") {
												taxValue = taxValue.slice(0, -1)
											}

											if (taxValue.slice(-1) == "0") {
												taxValue = taxValue.slice(0, -1)
											}

											if (taxValue.slice(-1) == ".") {
												taxValue = taxValue.slice(0, -1)
											}

											concept.taxVal = taxValue + (item.taxInPercentage ? "%" : "")
										}
									}

									return concept
								}
							)
					}
				}
			)

		dispatch(enableIdleLockSwitch())

		try {
			const result = await InvoicesService.updateInvoice
				(campusRef[currentUserCampus].id, student.id, conceptListing, editableInvoice)

			if (result.status == 200 || result.status == 204) {
				dispatch
					(
						displayNotice
							({
								cornerClose: false,
								procedure: resetFn,
								message: "Información procesada con éxito",
								heading: <h3 style={{ color: "#00FF00", display: "inline-block" }}>
									Listo
								</h3>
							})
					)
			}
			else {
				dispatch(disableIdleLockSwitch())
				procedureComplaint(updateError)
			}
		}
		catch (error) {
			console.log(error)
			dispatch(disableIdleLockSwitch())
			procedureComplaint(updateError)
		}
	}

	const downloadInvoiceAttachment = (filename: string) => {
		InvoicesService.downloadAttachmentFile
			(campusRef[currentUserCampus].id, student.id, selectedInvoice.id, filename).then
			(
				(result: any) => FileSaver.saveAs(new Blob([result.data], { type: "application/octet-stream" }), filename),
				(error: any) => console.log(error)
			)
	}

	const downloadPaymentAttachment = (filename: string) => {
		PaymentsService.downloadAttachmentFile
			(campusRef[currentUserCampus].id, student.id, selectedPayment, filename).then
			(
				(result: any) => FileSaver.saveAs(new Blob([result.data], { type: "application/octet-stream" }), filename),
				(error: any) => console.log(error)
			)
	}

	const generatePDF = (printableRef: any, filename: string) => {
		let pdf: any = new jsPDF("p", "pt", "a4")

		const margins: any =
		{
			top: 70,
			bottom: 40,
			left: 30,
			width: 30
		}

		pdf.setFontSize(18)
		pdf.html
			(
				printableRef.current.innerHTML,
				{
					x: margins.left,
					y: margins.top,
					width: margins.width,
					callback: () => {
						/*const totalPages : number = pdf.internal.pages.length
	
						for(var i : number = totalPages; i >= 1; i--)
						{
							pdf.setPage(i);
							//header
	
							var str = "Página " + i + " de " + totalPages
	
							pdf.setFontSize(10)
							pdf.text(str, margins.left, pdf.internal.pageSize.height - 20)
						}*/

						pdf.save(filename + ".pdf")
					}
				}
			)
	}

	return <div
		style={{ width: promptWidth[promptDisplayQueue[0]], background: "#FFFFFF", height: "100%", overflowY: "auto" }}
		className="sidePrompt"
	>
		<Box style={{ ...styles.showModal, width: "100%", height: "100%" }} sx={{ boxShadow: 2 }} role="presentation">
			<div className="card" style={{ ...styles.modalBody, overflowY: "auto", paddingBottom: "25px" }}>
				{
					promptDisplayQueue[0] != "checkout" || scheduleRef == null ? "" : <>
						<div style={{ display: "flex", alignItems: "stretch" }}>
							<h2 style={{ flexGrow: 1 }}>
								<CustomIcon name="shoppingCart" style={{ fill: "#8F91DA", width: "25px", height: "25px" }} />
								<span style={{ paddingLeft: "10px" }}>
									{Constants.Screens.Finances.modalShopping.cart}
								</span>
							</h2>
							<button style={{ ...styles.btnCloseModal, flexGrow: 0, zIndex: 2 }} type="button"
								onClick={() => setPromptDisplayQueue([])}
							>
								<FontAwesomeIcon style={{ height: "100%", position: "relative", bottom: "1px" }} icon={solid("times")} />
							</button>
						</div>
						<div style={styles.subtitleContainer}>
							<label style={{ ...styles.subtitle, position: "relative" }}>
								{Constants.Common.name}:
							</label>
							<span style={styles.boldText}>
								{student.first_name}
							</span>
						</div>
						<div style={styles.subtitleContainer}>
							<label style={{ ...styles.subtitle, position: "relative" }}>
								{Constants.Common.lastName}:
							</label>
							<span style={styles.boldText}>
								{student.last_name}
							</span>
						</div>
						<div style={styles.subtitleContainer}>
							<label style={{ ...styles.subtitle, position: "relative" }}>
								{Constants.Common.correlative}:
							</label>
							<span style={styles.boldText}>
								{student.student.identity_code}
							</span>
						</div>
						<br />
						<table>
							{
								Object.keys(scheduleChargeAddRef).length < 1 ? "" : <tbody>{
									Object.keys(scheduleChargeAddRef).map
										(
											(key: string, keyIndex: number) => <React.Fragment key={"CC_" + key}>
												<tr>
													<td colSpan={3}
														style={{ paddingBottom: "15px", borderTop: (keyIndex < 1 ? "" : "1px solid #000000") }}
													>
														<div>
															<label style={{ ...styles.subtitle, position: "relative", paddingRight: "5px" }}>
																{Constants.Common.course}:
															</label>
															<b style={{ color: "#5154AE" }}>
																{scheduleRef[key].name}
															</b>
														</div>
														<div>
															<label style={{ ...styles.subtitle, position: "relative", paddingRight: "5px" }}>
																{Constants.Common.period}:
															</label>
															<b style={{ color: "#2878A2" }}>
																{scheduleRef[key].period.name}
															</b>
														</div>
													</td>
												</tr>
												<tr>
													<td />
													<td style={{ ...styles.subtitle, textAlign: "center" }}>
														{Constants.Common.concept}
													</td>
													<td style={{ ...styles.subtitle, textAlign: "center" }}>
														{Constants.Common.amount}
													</td>
													<td style={{ ...styles.subtitle, textAlign: "center" }}>
														{Constants.Common.discount}
													</td>
													<td style={{ ...styles.subtitle, textAlign: "center" }}>
														{Constants.Common.tax}
													</td>
												</tr>
												{
													Object.values(scheduleChargeAddRef[key].chargeRef).map
														(
															(charge: any, chargeIndex: number) => <tr key={charge.refIndex}>
																<td>
																	<button className="btn btn-default" disabled={charge.disabled}
																		onClick={() => toggleCourseChargeSelection(charge)} type="button"
																	>
																		<CustomIcon name="bCheck" />
																	</button>
																</td>
																<td style={{ wordBreak: "break-all" }}>
																	{charge.title}
																</td>
																<td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
																	{currencySymbol}
																	{sprintf("%.02f", charge.sub_total)}
																</td>
																<td style={{ padding: "0 15px", textAlign: "right", whiteSpace: "nowrap", display: "flex", flexDirection: "column", alignItems: "center" }}>
																	<div style={{ border: "1px solid #8F91DA", borderRadius: "10px 10px 0px 10px", display: "inline-flex", width: "100%" }}>
																		<input name={"discountVal" + charge.refIndex} min={0}
																			max={charge.discountInPercentage ? 100 : +charge.amount}
																			style={{ width: "90%", borderRadius: "10px 0px 0px 10px", textAlign: "right" }}
																			onChange={(event: any) => handleDiscount(charge, event)}
																			id={"discountVal" + charge.refIndex} type="number"
																			// onFocus={validateField}
																			// onBlur={validateField}
																			value={+charge.discountValue} required
																		/>
																		<button className="btn btnVeryPeri withOptions" type="button"
																			onClick={() => handleTypeOfDiscount(charge)}
																			disabled={idleLocked}
																		>
																			{charge.discountInPercentage ? "%" : currencyCode}
																		</button>
																	</div>
																	{
																		!charge.discountInPercentage ? <></> : <span
																			style={{ color: Constants.Styles.text.colors.blackPrimary, fontSize: 12 }}
																		>
																			{currencySymbol}{sprintf("%.02f", charge.percentageCalculated)}
																		</span>
																	}
																</td>
																<td style={{ textAlign: "right", whiteSpace: "nowrap", padding: "0 10px" }}>
																	<input max={charge.discount ? 100 : charge.amount} min={0}
																		style={{ width: "100%", borderBottom: "1px solid black", textAlign: "right" }}
																		onChange={() => { console.log({ charge }) }} type="number"
																		name={charge.id + "taxVal"} value={charge.tax_value}
																		onFocus={validateField} id={charge + "taxVal"}
																		disabled={true} onBlur={validateField}
																	/>
																</td>
															</tr>
														)
												}
												{
													Object.values(scheduleChargeAddRef[key].kitRef).map
														(
															(kit: any) => <tr key={"K_" + kit.id}>
																<td>
																	<button onClick={() => toggleKitSelection(kit)} type="button"
																		className="btn btn-default" disabled={idleLocked}
																	>
																		<CustomIcon name="bCheck" />
																	</button>
																</td>
																<td style={{ wordBreak: "break-all" }}>
																	{kit.article.name}
																</td>
																<td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
																	{currencySymbol}
																	{sprintf("%.02f", +kit.amount)}
																</td>
																<td style={{ padding: "0 15px", textAlign: "right", whiteSpace: "nowrap", display: "flex", flexDirection: "column", alignItems: "center" }}>
																	<div style={{ border: "1px solid #8F91DA", borderRadius: "10px 10px 0px 10px", display: "inline-flex", width: "100%" }}>
																		<input onChange={(event: any) => handleDiscount(kit, event)}
																			style={{ width: "90%", borderRadius: "10px 0px 0px 10px", textAlign: "right" }}
																			name={"discountVal" + kit.refIndex} type="number" min={0}
																			value={+kit.discountValue} id={"discountVal" + kit.refIndex}
																			// onFocus={validateField}
																			// onBlur={validateField}
																			max={kit.discountInPercentage ? 100 : +kit.amount}
																			required
																		/>
																		<button className="btn btnVeryPeri withOptions" type="button"
																			disabled={idleLocked} onClick={() => handleTypeOfDiscount(kit)}
																		>
																			{kit.discountInPercentage ? "%" : currencyCode}
																		</button>
																	</div>
																	{
																		!kit.discountInPercentage ? <></> : <span
																			style={{ color: Constants.Styles.text.colors.blackPrimary, fontSize: 12 }}
																		>
																			{currencySymbol}{sprintf("%.02f", +kit.percentageCalculated)}
																		</span>
																	}
																</td>
																<td style={{ textAlign: "right", whiteSpace: "nowrap", padding: "0 10px" }}>
																	<input onChange={(event: any) => console.log({ kit })}
																		style={{ width: "100%", borderBottom: "1px solid black", textAlign: "right" }}
																		id={kit + "taxVal"} value={kit.tax_value} type="number"
																		max={kit.discount ? 100 : kit.amount} disabled={true}
																		onBlur={validateField} name={kit.id + "taxVal"} min={0}
																		onFocus={validateField}
																	/>
																</td>
															</tr>
														)
												}
											</React.Fragment>
										)
								}</tbody>
							}
							{
								Object.keys(kitRef).length < 1 ? "" : <tbody>
									<tr>
										<td colSpan={5}
											style={{ paddingBottom: "15px", borderTop: "1px solid #000000", color: "#5154AE", textTransform: "uppercase", fontWeight: 900 }}
										>
											{Constants.Common.kits}
										</td>
									</tr>
									{
										Object.values(kitRef).map
											(
												(article: any) => <tr key={article.refIndex}>
													<td>
														<button onClick={() => toggleKitSelection(article)} disabled={idleLocked}
															type="button" className="btn btn-default"
														>
															<CustomIcon name="bCheck" />
														</button>
													</td>
													<td style={{ wordBreak: "break-all" }}>
														{article.article.name}
													</td>
													<td style={{ textAlign: "right" }}>
														{currencySymbol}
														{sprintf("%.02f", article.sub_total)}
													</td>
													<td style={{ padding: "0 15px", textAlign: "right", whiteSpace: "nowrap", display: "flex", flexDirection: "column", alignItems: "center" }}>
														<div style={{ border: "1px solid #8F91DA", borderRadius: "10px 10px 0px 10px", display: "inline-flex", width: "100%" }}>
															<input max={article.discountInPercentage ? 100 : +article.amount}
																style={{ width: "90%", borderRadius: "10px 0px 0px 10px", textAlign: "right" }}
																name={"discountVal" + article.refIndex} min={0} type="number"
																// onFocus={validateField}
																// onBlur={validateField}
																onChange={(event: any) => handleDiscount(article, event)}
																value={+article.discountValue} id={"discountVal" + article.refIndex}
																required
															/>
															<button className="btn btnVeryPeri withOptions" type="button"
																disabled={idleLocked} onClick={() => handleTypeOfDiscount(article)}
															>
																{article.discountInPercentage ? "%" : currencyCode}
															</button>
														</div>
														{
															!article.discountInPercentage ? <></> : <span
																style={{ color: Constants.Styles.text.colors.blackPrimary, fontSize: 12 }}
															>
																{currencySymbol}{sprintf("%.02f", article.percentageCalculated)}
															</span>
														}
													</td>
													<td style={{ textAlign: "right", whiteSpace: "nowrap", padding: "0 10px" }}>
														<input onFocus={validateField}
															style={{ width: "100%", borderBottom: "1px solid black", textAlign: "right" }}
															name={article.id + "taxVal"} value={article.tax_value} id={article + "taxVal"}
															min={0} max={article.discount ? 100 : article.amount} type="number"
															onChange={(event: any) => console.log({ article })} disabled={true}
															onBlur={validateField}
														/>
													</td>
												</tr>
											)
									}
								</tbody>
							}
							{
								Object.keys(otherRef).length < 1 ? "" : <tbody>
									<tr><td colSpan={3}
										style={{ paddingBottom: "15px", borderTop: "1px solid #000000", color: "#5154AE", textTransform: "uppercase", fontWeight: 900 }}
									>
										{Constants.Common.others}
									</td></tr>
									{
										Object.values(otherRef).map
											(
												(article: any) => article == null ? "" : <tr key={article.refIndex}>
													<td>
														<button onClick={() => toggleOtherSelection(article)} disabled={idleLocked}
															className="btn btn-default"
														>
															<CustomIcon name="bCheck" />
														</button>
													</td>
													<td style={{ wordBreak: "break-all" }}>
														{article.article.name}
													</td>
													<td style={{ textAlign: "right" }}>
														{currencySymbol}
														{sprintf("%.02f", article.sub_total)}
													</td>
													<td style={{ padding: "0 15px", textAlign: "right", whiteSpace: "nowrap", display: "flex", flexDirection: "column", alignItems: "center" }}>
														<div style={{ border: "1px solid #8F91DA", borderRadius: "10px 10px 0px 10px", display: "inline-flex", width: "100%" }}>
															<input max={article.discountInPercentage ? 100 : +article.amount}
																style={{ width: "90%", borderRadius: "10px 0px 0px 10px", textAlign: "right" }}
																name={"discountVal" + article.refIndex} value={+article.discountValue}
																id={"discountVal" + article.refIndex} type="number" min={0}
																// onFocus={validateField}
																// onBlur={validateField}
																onChange={(event: any) => handleDiscount(article, event)}
																required
															/>
															<button className="btn btnVeryPeri withOptions" type="button"
																disabled={idleLocked} onClick={() => handleTypeOfDiscount(article)}
															>
																{article.discountInPercentage ? "%" : currencyCode}
															</button>
														</div>
														{
															!article.discountInPercentage ? <></> : <span
																style={{ color: Constants.Styles.text.colors.blackPrimary, fontSize: 12 }}
															>
																{currencySymbol}{sprintf("%.02f", article.percentageCalculated)}
															</span>
														}
													</td>
													<td style={{ textAlign: "right", whiteSpace: "nowrap", padding: "0 10px" }}>
														<input onFocus={validateField}
															style={{ width: "100%", borderBottom: "1px solid black", textAlign: "right" }}
															max={article.discount ? 100 : article.amount} id={article + "taxVal"}
															onChange={(event: any) => { console.log({ article }) }} disabled={true}
															onBlur={validateField} type="number"
															name={article.id + "taxVal"} value={article.tax_value} min={0}
														/>
													</td>
												</tr>
											)
									}
								</tbody>
							}
						</table>
						<hr style={{ border: "2px solid #000000", opacity: 1 }} />
						<div className="text-center">
							<br />
							<label style={{ position: "relative", fontWeight: 900 }}>
								{Constants.Common.totalText} : &nbsp;
							</label>
							<h2 style={{ display: "inline-block" }}>
								{/*{sprintf(currencySymbol + "%.02f", total)} */}
								{sprintf(currencySymbol + "%.02f", totalToPaid)}
							</h2>
							<br />
							{
								conceptsCount > 1
									?
									<label style={{ position: "relative", textTransform: "uppercase", color: "#CA413D" }}>
										{Constants.Common.noPartialPayments}
									</label>
									: totalToPaid > 0 ?
										<div style={{ paddingTop: "5px" }}>
											<button style={{ width: "50%" }} onClick={() => paymentPrompt()}
												type="button" className="btn btn-outline-danger rounded-pill"
											>
												{Constants.Common.partialPaymentText}
											</button>
										</div>
										: <></>
							}
							{totalToPaid > 0 ?
								<div style={{ paddingTop: "15px" }}>
									<button style={{ width: "50%" }} onClick={() => paymentPrompt(true)}
										type="button" className="btn btn-danger rounded-pill"
									>
										{Constants.Common.totalPaymentText}
									</button>
								</div>
								: <></>
							}
							{totalToPaid > 0 ? <></>
								: <div
									style={{
										paddingTop: "15px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center"
									}}
								>
									<label
										style={{
											width: "40%",
											position: "relative",
											textTransform: "uppercase",
											color: "#CA413D"
										}}
									>
										{Constants.Common.generateInvoiceLegend}
									</label>
									<br />
									<button style={{ width: "50%" }} onClick={() => paymentPrompt(true, true)}
										type="button" className="btn btn-danger rounded-pill"
									>
										{Constants.Common.generateInvoice}
									</button>
								</div>
							}
						</div>
					</>
				}
				{
					promptDisplayQueue[0] != "generateInvoice" || totalToPaid > 0 ? <></>
						: <>
							{
								<>
									<div style={{ display: "flex", alignItems: "stretch" }}>
										<h2 style={{ flexGrow: 1 }}>
											<span style={{ paddingLeft: "10px" }}>
												{Constants.Common.generateInvoice}
											</span>
										</h2>
										<button style={{ ...styles.btnCloseModal, flexGrow: 0, zIndex: 2 }} onClick={promptDismissal}>
											<FontAwesomeIcon style={{ height: "100%", position: "relative", bottom: "1px" }} icon={solid("times")} />
										</button>
									</div>
									<form ref={paymentFormRef} className="form">
										<div style={{ background: "#F1F1FF", padding: "15px" }}>
											<h5 style={{ display: "inline-block", width: "70%", textAlign: "center" }}>
												{Constants.Screens.Finances.modalPayments.payment.receive}({basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].code})
											</h5>
											<div style={{ display: "inline-block", width: "30%", textAlign: "right", borderBottom: "1px solid #7678BF" }}>
												<h5 style={{ marginBottom: "unset" }}>
													{sprintf("%.02f", totalToPaid)}
												</h5>
											</div>
										</div>
										<div style={{ padding: "10px" }}>
											<label htmlFor="notes" style={{ position: "relative" }}>
												{Constants.Common.notes}
											</label>
											<div style={{ wordBreak: "break-all", textOverflow: "unset" }}>
												<br />
												<textarea id="notes" value={editableInvoice.notes} onChange={handleInvoiceChange}
													onBlur={validateField} placeholder={Constants.Common.notes} maxLength={255}
													onFocus={validateField} name="notes" style=
													{{
														resize: "vertical",
														width: "100%",
														border: "1px solid #7678BF",
														borderRadius: "5px",
														minHeight: "150px",
														padding: "5px"
													}}
												/>
											</div>
										</div>
										<div style={{ textAlign: "center" }}>
											<button className="btn btn-outline-danger rounded-pill" type="button"
												disabled={idleLocked} onClick={generateInvoice} style={{ width: "50%" }}
											>
												{Constants.Common.generateInvoice}
											</button>
										</div>
									</form>
								</>
							}
						</>
				}
				{
					promptDisplayQueue[0] != "payment" || selectedPayment == null ? "" : <>
						{
							editablePayment == null || editablePayment.id == null ? "" : <div className="financePrint"
								ref={paymentPrintableRef} style={{ width: "548px" }}
							>
								<div style={{ width: "548px" }}>
									<div style={{ display: "flex", alignItems: "stretch" }}>
										<h2 style={{ flexGrow: 1 }}>
											<span style={{ paddingLeft: "10px" }}>
												{Constants.Screens.Finances.modalPayments.payment.title}
												&nbsp;
												{selectedPayment.invoice_id}
											</span>
										</h2>
									</div>
									<div style={{ display: "flex" }}>
										<div className="inputContainer" style={{ width: "50%", paddingTop: "2px" }}>
											<label htmlFor="name">
												{Constants.Common.student}
											</label>
											<div className="roView" style={{ borderBottom: "1px solid #7678BF" }}>
												<br />
												{student.first_name}
												&nbsp;
												{student.last_name}
											</div>
										</div>
										{
											selectedPayment == null || selectedPayment.id == null ? "" : <div style={{ width: "50%", textAlign: "right" }}>
												<label htmlFor="name" style={{ position: "relative" }}>
													{Constants.Screens.Finances.modalPayments.payment.number}
												</label>
												<div style={{ borderBottom: "1px solid #7678BF" }}>
													{selectedPayment.id}
												</div>
											</div>
										}
									</div>
									<form ref={paymentFormRef} className="form">
										<div style={{ background: "#F1F1FF", padding: "15px" }}>
											<h5 style={{ display: "inline-block", width: "70%", textAlign: "center" }}>
												{Constants.Screens.Finances.modalPayments.payment.receive}({basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].code})
											</h5>
											<div style={{ display: "inline-block", width: "30%", textAlign: "right", borderBottom: "1px solid #7678BF" }}>
												<h5 style={{ marginBottom: "unset" }}>
													{sprintf("%.02f", selectedPayment.amount)}
												</h5>
											</div>
										</div>
										<table style={{ marginTop: "15px", borderSpacing: "10px 0px", borderCollapse: "separate", width: "100%" }}>
											<tbody>
												<tr>
													<td style={{ width: "50%" }}>
														<label htmlFor="date" style={{ position: "relative" }}>
															{Constants.Screens.Finances.modalPayments.payment.date}{editablePayment && (editablePayment.method && !editablePayment.method.bool_payment_date_unmodifiable) ? "*" : ""}
														</label>
													</td>
													<td>
														<label htmlFor="status_id" style={{ position: "relative" }}>
															{Constants.Common.status}
														</label>
													</td>
												</tr>
												<tr>
													<td style={{ width: "50%", verticalAlign: "bottom" }}>
														<div className="roView">
															{toAscendingDate(new Date(selectedPayment.payment_date + " 23:00:00"))}
														</div>
													</td>
													<td style={{ verticalAlign: "bottom" }}>
														<div className="roView">
															{paymentStatusRef["PS_" + selectedPayment.status_id].name}
														</div>
													</td>
												</tr>
												<tr>
													<td style={{ width: "50%" }}>
														<label htmlFor="name" style={{ position: "relative" }}>
															{Constants.Screens.Finances.modalPayments.payment.method}
														</label>
													</td>
													<td>
														<label htmlFor="name" style={{ position: "relative" }}>
															{Constants.Screens.Finances.modalPayments.payment.depositTo}
														</label>
													</td>
												</tr>
												<tr>
													<td style={{ width: "50%", verticalAlign: "bottom" }}>
														<div className="roView"
															style={{ borderBottom: "1px solid #7678BF", whiteSpace: "unset", wordBreak: "break-all" }}
														>
															<br />
															{paymentMethodRef["PM_" + selectedPayment.method_id].label}
														</div>
													</td>
													<td style={{ verticalAlign: "bottom" }}>
														<div className="roView"
															style={{ borderBottom: "1px solid #7678BF", whiteSpace: "unset", wordBreak: "break-all" }}
														>
															<br />
															{/*{recipientAccountRef["RA_" + selectedPayment.account_id].label} */}
															{mapAccount(selectedPayment.account_id)}
														</div>
													</td>
												</tr>
												<tr><td colSpan={2} style={{ paddingRight: "10px", width: "50%" }}>
													<label htmlFor="reference_number"
														style={{ position: "relative" }}
													>
														{Constants.Common.reference}
													</label>
												</td>
												</tr>
												<tr>
													<td colSpan={2} style={{ paddingRight: "10px", verticalAlign: "bottom", width: "50%" }}>
														<div className="roView"
															style={{ borderBottom: "1px solid #7678BF", whiteSpace: "unset", wordBreak: "break-all" }}
														>
															<br />
															{selectedPayment.reference_number}
														</div>
													</td>
												</tr>
											</tbody>
										</table>
										<div style={{ padding: "10px" }}>
											<label htmlFor="notes" style={{ position: "relative" }}>
												{Constants.Common.notes}
											</label>
											<div style={{ wordBreak: "break-all", textOverflow: "unset" }}>
												<br />
												{selectedPayment.notes || "-"}
											</div>
										</div>
									</form>
								</div>
							</div>
						}
						<div style={{ display: "flex", alignItems: "stretch" }}>
							<h2 style={{ flexGrow: 1 }}>
								<span style={{ paddingLeft: "10px" }}>
									{Constants.Common.invoicePayment}
									&nbsp;
									{selectedPayment.invoice_id == null ? "nueva" : selectedPayment.invoice_id}
								</span>
							</h2>
							<button onClick={() => setPromptDisplayQueue(promptDisplayQueue[1] == null ? [] : [promptDisplayQueue[1]])}
								style={{ ...styles.btnCloseModal, flexGrow: 0, zIndex: 2 }} type="button"
							>
								<FontAwesomeIcon style={{ height: "100%", position: "relative", bottom: "1px" }} icon={solid("times")} />
							</button>
						</div>
						<div style={{ display: "flex" }}>
							<div className="inputContainer" style={{ width: "50%", paddingTop: "2px" }}>
								<label htmlFor="name">
									{Constants.Common.student}
								</label>
								<div className="roView" style={{ borderBottom: "1px solid #7678BF" }}>
									<br />
									{student.first_name}
									&nbsp;
									{student.last_name}
								</div>
							</div>
							{
								selectedPayment == null || selectedPayment.id == null ? "" : <div style={{ width: "50%", textAlign: "right" }}>
									<label htmlFor="name" style={{ position: "relative" }}>
										{Constants.Screens.Finances.modalPayments.payment.number}
									</label>
									<div style={{ borderBottom: "1px solid #7678BF" }}>
										{selectedPayment.id}
									</div>
								</div>
							}
						</div>
						<form ref={paymentFormRef} className="form">
							<div style={{ background: "#F1F1FF", padding: "15px" }}>
								<h5 style={{ display: "inline-block", width: "70%", textAlign: "center" }}>
									{Constants.Screens.Finances.modalPayments.payment.receive}({basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].code})
								</h5>
								<div style={{ display: "inline-block", width: "30%", textAlign: "right", borderBottom: "1px solid #7678BF" }}>{
									selectedPayment.id || editablePayment.fullPaymentRequired
										?
										<h5 style={{ marginBottom: "unset" }}>
											{sprintf("%.02f", selectedPayment.amount)}
										</h5>
										:
										<Popover positions={["bottom"]} isOpen={amountIsZero} content=
											{<div style={{ background: "var(--main-bg)", padding: "5px 5px 0px 5px", position: "relative" }}>
												<div className="requirementAlert">
													<FontAwesomeIcon icon={solid("exclamation")} />
												</div>
												&nbsp;
												{Constants.Common.gtZeroRequired}
											</div>}
										>
											<input onFocus={validateField} max={+editablePayment.limit} disabled={idleLocked}
												value={editablePayment.amount} placeholder={Constants.Common.price} name="amount"
												style={{ textAlign: "right", width: "100%" }} id="amount" onBlur={validateField}
												min={0} type="number" onChange=
												{
													(event: any) => setEditablePayment
														({
															...editablePayment, amount: event.target.value < 0 ? 0 :
																(event.target.value > editablePayment.limit ? editablePayment.amount : event.target.value)
														})
												} required
											/>
										</Popover>
								}</div>
							</div>
							<table style={{ marginTop: "15px", borderSpacing: "10px 0px", borderCollapse: "separate", width: "100%" }}>
								<tbody>
									<tr>
										<td style={{ width: "50%" }}>
											<label htmlFor="date" style={{ position: "relative" }}>
												{Constants.Screens.Finances.modalPayments.payment.date}{editablePayment && (editablePayment.method && !editablePayment.method.bool_payment_date_unmodifiable) ? "*" : ""}
											</label>
										</td>
										{
											selectedPayment.id == null ? "" : <td>
												<label htmlFor="status_id" style={{ position: "relative" }}>
													{Constants.Common.status}
												</label>
											</td>
										}
									</tr>
									<tr>
										<td style={{ width: "50%", verticalAlign: "bottom" }}>{
											selectedPayment.id
												?
												<div className="roView">
													{toAscendingDate(new Date(selectedPayment.payment_date + " 23:00:00"))}
												</div>
												:
												<div style={{ border: "1px solid  #7678BF" }}>
													<LeDatePicker onBlur={validateField} months={monthRef} name="dateValue"
														value={editablePayment.dateValue} onFocus={validateField}
														disabled={editablePayment.method && editablePayment.method.bool_payment_date_unmodifiable}
														required={editablePayment.method && !editablePayment.method.bool_payment_date_unmodifiable}
														years={basicInfo.futureRange} id="dateValue" onChange=
														{
															(e: any) => handlePaymentChange
																({
																	target:
																	{
																		name: "dateValue",
																		value: e.target.value
																	}
																})
														}
													/>
												</div>
										}</td>
										{
											selectedPayment.id == null ? "" : <td style={{ verticalAlign: "bottom" }}>
												<div
													className="roView"
												>
													{paymentStatusRef["PS_" + selectedPayment.status_id].name}
												</div>
											</td>
										}
									</tr>
									<tr>
										<td style={{ width: "50%" }}>
											<label htmlFor="name" style={{ position: "relative" }}>
												{Constants.Screens.Finances.modalPayments.payment.method}{editablePayment ? "*" : ""}
											</label>
										</td>
										<td>
											<label htmlFor="name" style={{ position: "relative" }}>
												{Constants.Screens.Finances.modalPayments.payment.depositTo}{editablePayment ? "*" : ""}
											</label>
										</td>
									</tr>
									<tr>
										<td style={{ width: "50%", verticalAlign: "bottom" }}>{
											selectedPayment.id
												?
												<div className="roView"
													style={{ borderBottom: "1px solid #7678BF", whiteSpace: "unset", wordBreak: "break-all" }}
												>
													<br />
													{paymentMethodRef["PM_" + selectedPayment.method_id].label}
												</div>
												:
												<Popover positions={["bottom"]} isOpen={noMethodProvided} content=
													{<div style={{ background: "var(--main-bg)", padding: "5px 5px 0px 5px", position: "relative" }}>
														<div className="requirementAlert">
															<FontAwesomeIcon icon={solid("exclamation")} />
														</div>
														&nbsp;
														{Constants.Common.optionRequired}
													</div>}
												>
													<div>
														<SelectReact value={editablePayment.method} children={Object.values(paymentMethodRef)}
															className="btn btn-primary text-start" style={{ height: "30px" }} disabled={idleLocked}
															placeholder={Constants.Screens.Finances.modalPayments.payment.method}
															id="method" name="method" onChange={handlePaymentChange}
														/>
													</div>
												</Popover>
										}</td>
										<td style={{ verticalAlign: "bottom" }}>{
											selectedPayment.id
												?
												<div className="roView"
													style={{ borderBottom: "1px solid #7678BF", whiteSpace: "unset", wordBreak: "break-all" }}
												>
													<br />
													{/*{recipientAccountRef["RA_" + selectedPayment.account_id].label} */}
													{mapAccount(selectedPayment.account_id)}
												</div>
												:
												<Popover positions={["bottom"]} isOpen={noAccountProvided} content=
													{<div style={{ background: "var(--main-bg)", padding: "5px 5px 0px 5px" }}>
														<div className="requirementAlert">
															<FontAwesomeIcon icon={solid("exclamation")} />
														</div>
														&nbsp;
														{Constants.Common.optionRequired}
													</div>}
												>
													<div>
														<SelectReact placeholder="Cuenta receptora" className="btn btn-primary text-start"
															id="account" name="account"
															value=
															{
																Object.values(recipientAccountRef).length === 1 && !isEmpty(editablePayment.method)
																	? recipientAccountRef[0]
																	: editablePayment.account
															}
															style={{ height: "30px" }}
															children={Object.values(recipientAccountRef)} onChange={handlePaymentChange}
															disabled={idleLocked || (editablePayment && editablePayment.method && isEmpty(editablePayment.method))}
														/>
													</div>
												</Popover>
										}</td>
									</tr>
									{
										(selectedPayment.id && selectedPayment.reference_number == null) ||
											(editablePayment && (editablePayment.method && editablePayment.method.bool_payment_reference_nullified))
											?
											""
											:
											<>
												<tr>
													<td colSpan={2} style={{ paddingRight: "10px", width: "50%" }}>
														<label
															style={{ position: "relative" }} htmlFor="reference_number"
														>
															{Constants.Common.reference}{editablePayment ? "*" : ""}
														</label>
													</td>
												</tr>
												<tr>
													<td colSpan={2} style={{ paddingRight: "10px", verticalAlign: "bottom", width: "50%" }}>{
														selectedPayment.id
															?
															<div className="roView"
																style={{ borderBottom: "1px solid #7678BF", whiteSpace: "unset", wordBreak: "break-all" }}
															>
																<br />
																{selectedPayment.reference_number}
															</div>
															:
															editablePayment.method && editablePayment.method.bool_payment_reference_nullified
																?
																<div style={{ background: "#F1F1F1", padding: "5px", borderRadius: "15px" }}>
																	El método de pago seleccionado hace de la referencia un valor no necesario
																</div>
																:
																<input disabled={idleLocked} value={editablePayment.reference_number}
																	style={{ width: "100%", border: "1px solid #7678BF", borderRadius: "5px" }}
																	required={editablePayment.method && !editablePayment.method.bool_payment_reference_nullified}
																	onChange={handlePaymentChange} type="text" name="reference_number"
																	onFocus={validateField} onBlur={validateField}
																	id="reference_number"
																/>
													}</td>
												</tr>
											</>
									}
								</tbody>
							</table>
							<div style={{ padding: "10px" }}>
								<label htmlFor="notes" style={{ position: "relative" }}>
									{Constants.Common.notes}
								</label>
								{
									(selectedPayment.id && selectedPayment.status_id != paymentStatus.PENDING_APPROVAL)
										?
										<div style={{ wordBreak: "break-all", textOverflow: "unset" }}>
											<br />
											{selectedPayment.notes || "-"}
										</div>
										:
										<textarea id="notes" value={editablePayment.notes} onChange={handlePaymentChange}
											onBlur={validateField} placeholder={Constants.Common.notes} maxLength={255}
											onFocus={validateField} name="notes" style=
											{{
												resize: "vertical",
												width: "100%",
												border: "1px solid #7678BF",
												borderRadius: "5px",
												minHeight: "150px",
												padding: "5px"
											}}
										/>
								}
							</div>
						</form>
						<UnderlineHeading name={"Archivos adjuntos"} />
						<div>
							{
								(editablePayment == null || Object.keys(editablePayment.file_ref).length < 1) &&
									((selectedPayment.status_id != paymentStatus.PENDING_APPROVAL) && selectedPayment.file_ref == null)
									?
									"-"
									:
									Object.keys(editablePayment.file_ref).map
										(
											(filename: string) => {
												const half: number = Math.ceil(filename.length / 2)

												return <div style={{ display: "flex" }} key={filename}>
													{
														(selectedPayment.id && selectedPayment.status_id != paymentStatus.PENDING_APPROVAL)
															?
															""
															:
															<button onClick={() => removePaymentAttachment(filename)} type="button"
																className="btn btn-default" disabled={idleLocked}
															>
																<FontAwesomeIcon icon={solid("times")} />
															</button>
													}
													{
														half
															?
															(
																selectedPayment.file_ref[filename] != ""
																	?
																	<>
																		<span className="filename" style={{ textOverflow: "ellipsis" }}>
																			{filename.substr(0, half)}
																		</span>
																		<span className="filename ellipsis reverse-ellipsis">
																			<span>
																				{filename.substr(half, filename.length)}
																			</span>
																		</span>
																	</>
																	:
																	<div style=
																		{{
																			paddingTop: "7px",
																			width: selectedPayment.id && selectedPayment.status_id
																				!= paymentStatus.PENDING_APPROVAL ? "100%" : "calc(100% - 50px)"
																		}}
																	>
																		<u style={{ cursor: "pointer" }}
																			onClick={() => { !idleLocked && downloadPaymentAttachment(filename) }}
																		>
																			<span className="filename" style={{ textOverflow: "ellipsis" }}>
																				{filename.substr(0, half)}
																			</span>
																			<span className="filename ellipsis reverse-ellipsis">
																				<span>
																					{filename.substr(half, filename.length)}
																				</span>
																			</span>
																		</u>
																	</div>
															)
															:
															""
													}
												</div>
											}
										)
							}
							{
								selectedPayment.id && selectedPayment.status_id != paymentStatus.PENDING_APPROVAL ? null : <>
									<button
										className="btn btn-default"
										onClick={() => buttonClickHandling(paymentFileInputRef)}
										type="button"
										style={{ background: "#FFFFFF", border: "2px dotted #000000" }}
										disabled={idleLocked || selectedInvoice.status_id === invoiceStatus.CANCELLED}
									>
										<FontAwesomeIcon icon={solid("arrow-up-from-bracket")} />
										{Constants.Screens.Finances.modalPayments.uploadFile}
									</button>
									<input
										type="file"
										onChange={(e: any) => onSelectPaymentAttachment(e)}
										ref={paymentFileInputRef}
										style={{ display: "none" }}
									/>
								</>
							}
						</div>
						{
							(selectedPayment.id == null) ? "" : <>
								<button className="btn btnOutlinePurple rounded-pill" style={{ width: "50%" }} type="button"
									disabled={idleLocked} onClick={() => generatePDF(paymentPrintableRef, "pago")}
								>
									<FontAwesomeIcon icon={regular("circle-down")} style={{ paddingRight: "10px" }} />
									{Constants.Screens.Finances.modalPayments.downloadFile}
								</button>
								<br />
								<br />
								<button className="btn btn-outline-dark rounded-pill" type="button" onClick={removePayment}>
									{Constants.Screens.Finances.modalPayments.delete}
								</button>
							</>
						}
						<br />
						<br />
						<div style={{ textAlign: "right" }}>
							{
								selectedPayment.id ? "" : <button className="btn btn-outline-danger rounded-pill" type="button"
									disabled={idleLocked} onClick={processPayment} style={{ width: "50%" }}
								>
									{Constants.Screens.Finances.modalPayments.btnPayment}
								</button>
							}
							<button onClick={() => setPromptDisplayQueue([promptDisplayQueue[1]])} disabled={idleLocked} type="button"
								style={{ width: "50%", border: "1px solid #000000" }} className="btn btn-outline-default rounded-pill"
							>
								{Constants.Common[editablePayment == null ? "close" : "cancel"]}
							</button>
						</div>
					</>
				}
				{
					promptDisplayQueue[0] != "invoice" || editableInvoice == null ? "" : <>
						{/*---------------------------------------------------------------------------------------------------*/}
						<div style={{ display: "flex", alignItems: "stretch" }}>
							<h2 style={{ flexGrow: 1 }}>
								<span style={{ paddingLeft: "10px" }}>
									{Constants.Common.invoice}{selectedInvoice.invoice_number}
								</span>
							</h2>
							<button style={{ ...styles.btnCloseModal, flexGrow: 0, zIndex: 2 }} onClick={promptDismissal}>
								<FontAwesomeIcon style={{ height: "100%", position: "relative", bottom: "1px" }} icon={solid("times")} />
							</button>
						</div>
						<div style={{ display: "flex", alignItems: "stretch", flexWrap: "wrap" }}>
							<div style={{ display: "inline-flex", alignItems: "baseline" }}>
								<label style={{ position: "relative", padding: "0px 5px" }}>
									{Constants.Common.status}
								</label>
								{handleStatusInvoiceById(selectedInvoice.status_id)}
							</div>
							<div style={{ display: "inline-flex", alignItems: "baseline" }}>
								<label style={{ position: "relative", padding: "0px 5px" }}>
									{Constants.Common.dueDate}
								</label>
								<div>
									{toAscendingDate(new Date(selectedInvoice.invoice_date + " 23:00:00"))}
								</div>
							</div>
							<div style={{ display: "inline-flex", alignItems: "baseline" }}>
								<label style={{ position: "relative", padding: "0px 5px" }}>
									{Constants.Common.due}
								</label>
								<div>
									{toAscendingDate(new Date(selectedInvoice.due_date + " 23:00:00"))}
								</div>
							</div>
						</div>
						<div>
							<UnderlineHeading name={"Alumno"} />
							<div className="row">
								<div className="col-sm-6">
									<div className="inputContainer">
										<label style={{ position: "relative" }}>
											{Constants.Common.names}
										</label>
										<div style={{ borderBottom: "2px solid #8F91DA" }}>
											{student.first_name}
										</div>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="inputContainer">
										<label style={{ position: "relative" }}>
											{Constants.Common.lastNames}
										</label>
										<div style={{ borderBottom: "2px solid #8F91DA" }}>
											{student.last_name}
										</div>
									</div>
								</div>
							</div>
						</div>
						{selectedInvoice.status_id === invoiceStatus.CANCELLED ?
							<>
								<table className="specialTable" style={{ width: "100%" }}>
									<thead>
										<tr>
											<th ref={el => { if (el) el.style.setProperty("border-bottom", "2px solid #C0C0C0", "important") }}
												style={{ width: "20%" }}
											>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA", textAlign: "center" }}>
													{Constants.Common.concept}
												</label>
											</th>
											<th style={{ borderBottom: "2px solid #C0C0C0", width: "1px" }}>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.quantity}
												</label>
											</th>
											<th style={{ borderBottom: "2px solid #C0C0C0", width: "1px" }}>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.price}
												</label>
											</th>
											<th ref={el => { if (el) { el.style.setProperty("border-bottom", "2px solid #C0C0C0", "important") } }}
												style={{ borderBottom: "2px solid #C0C0C0", width: "1px" }}
											>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.invoiceAmount}
												</label>
											</th>
										</tr>
									</thead>
									{selectedInvoice.invoice_items.map((item: any) =>
										<tbody>
											<td ref={el => { if (el) { el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") } }}
												style={{ verticalAlign: "bottom", width: "20%", padding: "unset" }}
											>
												<label style={{ position: "relative", wordBreak: "break-all", whiteSpace: "unset" }}>
													<b style={{ color: (editableInvoice.status_id === invoiceStatus.PARTLY_PAID ? "#C32722" : "#000000") }}>
														{item.title}
													</b>
												</label>
												{
													isEmpty(item.title) ? "" : <div style={{ wordBreak: "break-all", width: "100%" }}>
														<label style={{ position: "relative", whiteSpace: "unset" }}>
															{item.description}
														</label>
													</div>
												}
											</td>
											<td ref={el => { if (el) { el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") } }}
												style={{ borderBottom: "1px solid #C7C7C7", textAlign: "center", width: "1px", verticalAlign: "bottom", padding: "unset" }}>
												{item.units}
											</td>
											<td ref={el => { if (el) { el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") } }}
												style={{ borderBottom: "1px solid #C7C7C7", textAlign: "center", width: "1px", verticalAlign: "bottom", padding: "unset" }}>
												{sprintf(currencySymbol + " %.02f", item.price)}

											</td>
											<td ref={el => { if (el) { el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") } }}
												style={{ borderBottom: "1px solid #C7C7C7", textAlign: "center", width: "1px", verticalAlign: "bottom", padding: "unset" }}>
												{sprintf(currencySymbol + " %.02f", item.total)}
											</td>
										</tbody>
									)}
								</table>
							</>
							: <form ref={invoiceFormRef}>
								<table className="specialTable" style={{ width: "100%" }}>
									<thead>
										<tr>
											<th ref={el => { if (el) el.style.setProperty("border-bottom", "2px solid #C0C0C0", "important") }}
												style={{ width: "20%" }}
											>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA", textAlign: "center" }}>
													{Constants.Common.concept}
												</label>
											</th>
											<th style={{ borderBottom: "2px solid #C0C0C0", width: "1px" }}>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.quantity}
												</label>
											</th>
											<th style={{ borderBottom: "2px solid #C0C0C0", width: "1px" }}>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.price}
												</label>
											</th>
											<th style={{ borderBottom: "2px solid #C0C0C0", width: "20%" }}>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.discount}
												</label>
											</th>
											<th ref={el => { if (el) { el.style.setProperty("border-bottom", "2px solid #C0C0C0", "important") } }}
												style={{ borderBottom: "2px solid #C0C0C0", width: "1px" }}
											>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.invoiceAmount}
												</label>
											</th>
											<th ref={el => { if (el) { el.style.setProperty("border-bottom", "2px solid #C0C0C0", "important") } }}
												style={{ borderBottom: "2px solid #C0C0C0", width: "1px", textAlign: "center" }}>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.tax}
												</label>
											</th>
										</tr>
									</thead>
									{
										invoiceListing.map
											(
												(conceptListing: string, conceptIndex: number) =>
												(
													editableInvoice[conceptListing] == null ? "" : <tbody key={"TC_" + conceptIndex}>{
														editableInvoice[conceptListing].map
															(
																(concept: any, index: number) => <tr key={conceptListing + concept.id}>
																	<td ref={el => { if (el) { el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") } }}
																		style={{ verticalAlign: "bottom", width: "20%", padding: "unset" }}
																	>
																		<label style={{ position: "relative", wordBreak: "break-all", whiteSpace: "unset" }}>
																			<b style={{ color: (editableInvoice.status_id === invoiceStatus.PARTLY_PAID ? "#C32722" : "#000000") }}>
																				{concept.description}
																			</b>
																		</label>
																		{
																			isEmpty(concept.title) ? "" : <div style={{ wordBreak: "break-all", width: "100%" }}>
																				<label style={{ position: "relative", whiteSpace: "unset" }}>
																					{concept.title}
																				</label>
																			</div>
																		}
																	</td>
																	<td style={{ borderBottom: "1px solid #C7C7C7", textAlign: "right", width: "1px", verticalAlign: "bottom", padding: "unset" }}>
																		{concept.quantity}
																	</td>
																	<td style={{ borderBottom: "1px solid #C7C7C7", textAlign: "right", width: "1px", whiteSpace: "nowrap", verticalAlign: "bottom", padding: "unset" }}>
																		{sprintf(currencySymbol + " %.02f", concept.price)}
																	</td>
																	<td style={{ borderBottom: "1px solid #C7C7C7", textAlign: "right", verticalAlign: "bottom", width: "20%", padding: "unset" }}>
																		{
																			(concept.discountValue > 0 && concept.discountInPercentage)
																				?
																				<div style={{ textAlign: "center" }}>
																					{"(" + sprintf("%.02f", +concept.discountValue) + " %" + ")"}
																				</div>
																				:
																				<div style={{ textAlign: "center" }}>
																					{sprintf(currencySymbol + "%.02f", +concept.discountAmount)}
																				</div>
																		}
																		<div style={{ textAlign: "center" }}>{
																			!concept.discountInPercentage || concept.discountValue == 0 ? "" : sprintf
																				(currencySymbol + " %.02f", concept.discountAmount)
																		}</div>
																	</td>
																	<td style={{ borderBottom: "1px solid #C7C7C7", textAlign: "right", width: "1px", whiteSpace: "nowrap", verticalAlign: "bottom", padding: "unset" }}>
																		{sprintf(currencySymbol + " %.02f", calculateTotalItem(concept))}
																	</td>
																	<td ref={el => { if (el) { el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") } }}
																		style={{ borderBottom: "1px solid #C7C7C7", textAlign: "right", width: "1px", whiteSpace: "nowrap", verticalAlign: "bottom", padding: "unset" }}>
																		{sprintf(currencySymbol + " %.02f", (concept.taxValue))}
																	</td>
																</tr>
															)
													}</tbody>
												)
											)
									}
									{/*Invoice concept editting-----------------------------------------------------------------------------------------------*/}
								</table>
							</form>}
						<div style={{ textAlign: "right" }}>
							<div style={{ display: "inline-flex" }}>
								<label style={{ position: "relative", padding: "10px" }}>
									{Constants.Common.totalText}
								</label>
								{
									selectedInvoice.status_id === invoiceStatus.CANCELLED
										? <div className="inputContainer">
											<h3>
												{currencySymbol}
												&nbsp;
												{sprintf("%.02f", selectedInvoice.paid_amount)}
											</h3>
										</div>
										:
										editableInvoice == null || editableInvoice.total == null ? <></>
											: <div className="inputContainer">
												<h3>
													{currencySymbol}
													&nbsp;
													{sprintf("%.02f", editableInvoice.total)}
												</h3>
											</div>
								}
							</div>
							<br />
							<br />
							{
								(editableInvoice.status_id === invoiceStatus.FULLY_PAID) ? "" :
									<button disabled={selectedInvoice.status_id === invoiceStatus.CANCELLED}
										style={{ width: "50%" }}
										className="btn btn-outline-danger rounded-pill"
										type="button"
										onClick={() => {
											if (invoiceFormRef && invoiceFormRef.current && !invoiceFormRef.current.checkValidity()) {
												invoiceFormRef.current.reportValidity()
											}
											else {
												paymentPrompt((editableInvoice.owed_tuitions.length + editableInvoice.owed_articles.length) > 1)
											}
										}
										}
									>
										{Constants.Screens.Finances.modalPayments.emitPayment}
									</button>
							}
						</div>
						<br />
						<div className="row">
							<div className="col-sm-6">
								<label style={{ position: "relative" }}>
									{Constants.Common.notes}
								</label>
								{
									editableInvoice.status_id === invoiceStatus.FULLY_PAID
										?
										<div style={{ wordBreak: "break-word" }}>
											{editableInvoice.notes}
										</div>
										:
										<textarea value={editableInvoice.notes} onChange={handleInvoiceChange} disabled={idleLocked}
											name="notes" id="notes" maxLength={255} style=
											{{
												padding: "5px", border: "1px solid #000000", resize: "vertical", width: "100%",
												minHeight: "75px"
											}}
										/>
								}
							</div>
							<div className="col-sm-6">
								<label style={{ position: "relative" }}>
									{Constants.Screens.Finances.modalInvoiceDetail.files}
								</label>
								<div>
									{
										editableInvoice.status_id === invoiceStatus.FULLY_PAID ? "" : <>
											<button
												onClick={() => buttonClickHandling(invoiceFileInputRef)}
												className="btn btn-default"
												type="button"
												disabled={idleLocked || selectedInvoice.status_id === invoiceStatus.CANCELLED}
												style={{
													background: "#F1F1FF",
													border: "2px dashed #8F91DA",
													width: "100%",
													color: "#8F91DA"
												}}>
												<FontAwesomeIcon icon={solid("arrow-up-from-bracket")} style={{ paddingRight: "10px" }} />
												{Constants.Screens.Finances.modalInvoiceDetail.btnLoad}
											</button>
											<input onChange={onSelectInvoiceAttachment} ref={invoiceFileInputRef} type="file"
												style={{ display: "none" }}
											/>
										</>
									}
									{//Display of attachment filenames. Ellipsis-trimmed in the middle in case of large strings
										editableInvoice.file_ref == null ? "" : Object.keys(editableInvoice.file_ref).map
											(
												(filename: string) => {
													const half: number = Math.ceil(filename.length / 2)

													return <div style={{ width: "100%", display: "inline-flex" }} key={filename}>
														{
															editableInvoice.status_id === invoiceStatus.FULLY_PAID ? "" : <button disabled={idleLocked}
																onClick={() => removeInvoiceAttachment(filename)} type="button"
																className="btn btn-default"
															>
																<FontAwesomeIcon icon={solid("times")} />
															</button>
														}
														{
															half
																?
																<div style={{ display: "inline-block", width: "calc(100% - 45px)" }}>
																	<div style={{ paddingTop: "7px" }}>
																		{
																			editableInvoice.file_ref[filename] != ""
																				?
																				<>
																					<span className="filename" style={{ textOverflow: "ellipsis" }}>
																						{filename.substr(0, half)}
																					</span>
																					<span className="filename ellipsis reverse-ellipsis">
																						<span>
																							{filename.substr(half, filename.length)}
																						</span>
																					</span>
																				</>
																				:
																				<u style={{ cursor: "pointer" }}
																					onClick={() => !idleLocked && downloadInvoiceAttachment(filename)}
																				>
																					<span className="filename" style={{ textOverflow: "ellipsis" }}>
																						{filename.substr(0, half)}
																					</span>
																					<span className="filename ellipsis reverse-ellipsis">
																						<span>
																							{filename.substr(half, filename.length)}
																						</span>
																					</span>
																				</u>
																		}
																	</div>
																</div>
																:
																""
														}
													</div>
												}
											)
									}
								</div>
							</div>
							{
								(editableInvoice.payments == null || editableInvoice.payments.length < 1) ? "" : <>
									<UnderlineHeading name={"Pagos Recibidos"} />
									<table className="commonTable">
										<thead>
											<tr>
												<th style={{ fontWeight: "300" }}
													ref={el => { if (el) el.style.setProperty("border-bottom", "1px solid #000000", "important") }}
												>
													<label style={{ position: "relative", color: "#8F91DA" }}>
														{Constants.Common.date}
													</label>
												</th>
												<th style={{ fontWeight: "300" }}>
													<label style={{ position: "relative", color: "#8F91DA" }}>
														{Constants.Screens.Finances.modalPayments.payment.number}
													</label>
												</th>
												<th style={{ fontWeight: "300" }}>
													<label style={{ position: "relative", color: "#8F91DA" }}>
														{Constants.Common.status}
													</label>
												</th>
												<th style={{ fontWeight: "300" }}
													ref={el => { if (el) { el.style.setProperty("border-bottom", "1px solid #000000", "important") } }}
												>
													<label style={{ position: "relative", color: "#8F91DA" }}>
														{Constants.Common.balance}
													</label>
												</th>
											</tr>
										</thead>
										<tbody>{
											editableInvoice.payments.map
												(
													(payment: any) => <tr style={{ cursor: "pointer", display: "table-row" }}
														key={"IP_" + payment.id} className="card" onClick=
														{
															() => {
																if (!idleLocked) {
																	if
																		(
																		payment.status_id == paymentStatus.PENDING_APPROVAL &&
																		(invoiceFormRef && invoiceFormRef.current && !invoiceFormRef.current.checkValidity())
																	) {
																		invoiceFormRef.current.reportValidity()
																	}
																	else {
																		showPaymentPrompt({ ...payment, notes: payment.notes || "" })
																	}
																}
															}
														}
													>
														<td ref={el => { if (el) el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") }}>
															{toAscendingDate(new Date(payment.payment_date + " 23:00:00"))}
														</td>
														<td style={{ textAlign: "right" }}>
															{payment.id}
														</td>
														<td>
															<div style={{ display: "flex", alignItems: "stretch" }}>
																<div style={{ flex: "1" }}>
																	{paymentStatusRef["PS_" + payment.status_id].name}
																</div>
																<div>
																	<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Detalle De Pago</Tooltip>}>
																		<FontAwesomeIcon icon={solid("chevron-right")} />
																	</OverlayTrigger>
																</div>
															</div>
														</td>
														<td style={{ textAlign: "right" }}
															ref={el => { if (el) el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") }}
														>
															{currencySymbol}
															&nbsp;
															{sprintf("%.02f", +payment.amount)}
														</td>
													</tr>
												)
										}</tbody>
									</table>
								</>
							}
							<div style={{ padding: "30px" }}>
								<table style={{ width: "100%" }}>
									<tbody>
										<tr>
											<td style={{ width: "100%", textAlign: "right" }}>
												<label style={{ position: "relative", paddingRight: "10px" }}>
													{Constants.Screens.Finances.modalPayments.approvedTotal}
												</label>
											</td>
											<td style={{ paddingRight: "15px" }}>
												<h3>
													{currencySymbol}
												</h3>
											</td>
											<td style={{ textAlign: "right" }}>
												<h3>
													{sprintf("%.02f", +editableInvoice.approvedPaidTotal)}
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ textAlign: "right", paddingTop: "25px" }}>
								<button style={{ width: "50%" }} type="button"
									className="btn btnOutlinePurple rounded-pill" disabled={idleLocked} onClick={handleInvoicePdf}
								>
									<FontAwesomeIcon icon={regular("circle-down")} style={{ paddingRight: "10px" }} />
									{Constants.Screens.Finances.modalInvoiceDetail.btnDownload}
								</button>
							</div>
							<br />
							<br />
							<br />
							<br />
							{
								<button className="btn btn-warning" type="button"
									disabled={
										idleLocked
										|| editableInvoice.owed_tuitions.length > 0 && (latestInvoices && !latestInvoices?.includes(editableInvoice.id))
										|| editableInvoice.status_id === invoiceStatus.CANCELLED
									}
									onClick={() => setPromptDisplayQueue(["cancelling", ...promptDisplayQueue])}
								>
									{Constants.Common.cancel}
								</button>
							}
							{
								editableInvoice.status_id === invoiceStatus.FULLY_PAID ? ""
									: <button type="button"
										style={{ marginTop: "25px" }}
										className="btn btn-primary"
										disabled={idleLocked || selectedInvoice.status_id === invoiceStatus.CANCELLED}
										onClick=
										{() => {
											if (invoiceFormRef && invoiceFormRef.current && !invoiceFormRef.current.checkValidity()) {
												invoiceFormRef.current.reportValidity()
											}
											else {
												if
													(
													(editableInvoice.owed_tuitions.length + editableInvoice.owed_articles.length) > 1
													&&
													(
														(editableInvoice.pendingPaidTotal > 0)
														&& (editableInvoice.pendingPaidTotal != editableInvoice.total)
													)
												) {
													procedureComplaint
														(
															"Debido a que hay más de un concepto, " +
															"sólo se permite tener un pago pendiente cuya cantidad salde por completo el total de la factura"
														)
												}
												else {
													if
														(
														(editableInvoice.pendingPaidTotal + editableInvoice.approvedPaidTotal)
														> editableInvoice.total
													) {
														procedureComplaint
															(
																"La suma de pagos pendientes y aprobados es mayor al total reflejado en la factura. " +
																"Deberá cancelar algunos pagos pendientes, eliminar algunas aprobaciones " +
																"o ajustar los descuentos e impuestos aplicados a los conceptos en esta factura antes de poder proceder a emitir un nuevo pago."
															)
													}
													else {
														updateInvoice()
													}
												}
											}
										}
										}
									>
										{Constants.Common.saveChanges}
									</button>
							}
						</div>
					</>
				}
				{
					promptDisplayQueue[0] != "cancelling" ? "" : <>
						<div style={{ display: "flex", alignItems: "stretch" }}>
							<h2 style={{ flexGrow: 1 }}><span style={{ paddingLeft: "10px" }}>
								{Constants.Common.invoice}{selectedInvoice.invoice_number}
							</span></h2>
							<button style={{ ...styles.btnCloseModal, flexGrow: 0, zIndex: 2 }} onClick={() => setPromptDisplayQueue(["invoice"])}>
								<FontAwesomeIcon style={{ height: "100%", position: "relative", bottom: "1px" }} icon={solid("times")} />
							</button>
						</div>
						<div style={{ display: "flex", alignItems: "stretch", flexWrap: "wrap" }}>
							<div style={{ display: "inline-flex", alignItems: "baseline" }}>
								<label style={{ position: "relative", padding: "0px 5px" }}>
									{Constants.Common.status}
								</label>
								<div>{
									handleStatusInvoiceById(selectedInvoice.status_id)
								}</div>
							</div>
							<div style={{ display: "inline-flex", alignItems: "baseline" }}>
								<label style={{ position: "relative", padding: "0px 5px" }}>
									{Constants.Common.dueDate}
								</label>
								<div>
									{toAscendingDate(new Date(selectedInvoice.invoice_date + " 23:00:00"))}
								</div>
							</div>
							<div style={{ display: "inline-flex", alignItems: "baseline" }}>
								<label style={{ position: "relative", padding: "0px 5px" }}>
									{Constants.Common.due}
								</label>
								<div>
									{toAscendingDate(new Date(selectedInvoice.due_date + " 23:00:00"))}
								</div>
							</div>
						</div>
						<div>
							<UnderlineHeading name={"Alumno"} />
							<div className="row">
								<div className="col-sm-6">
									<div className="inputContainer">
										<label style={{ position: "relative" }}>
											{Constants.Common.names}
										</label>
										<div style={{ borderBottom: "2px solid #8F91DA" }}>
											{student.first_name}
										</div>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="inputContainer">
										<label style={{ position: "relative" }}>
											{Constants.Common.lastNames}
										</label>
										<div style={{ borderBottom: "2px solid #8F91DA" }}>
											{student.last_name}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<form ref={invoiceFormRef}>
								<table className="specialTable" style={{ width: "100%" }}>
									<thead>
										<tr>
											<th style={{ width: "1px" }} />
											<th ref={el => { if (el) { el.style.setProperty("border-bottom", "2px solid #C0C0C0", "important") } }}
												style={{ width: "20%" }}
											>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.detail}
												</label>
											</th>
											<th style={{ borderBottom: "2px solid #C0C0C0", width: "1px" }}>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.units}
												</label>
											</th>
											<th style={{ borderBottom: "2px solid #C0C0C0", width: "1px" }}>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.price}
												</label>
											</th>
											<th style={{ borderBottom: "2px solid #C0C0C0", width: "1px" }}>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.totalText}
												</label>
											</th>
											<th ref={el => { if (el) { el.style.setProperty("border-bottom", "2px solid #C0C0C0", "important") } }}
												style={{ borderBottom: "2px solid #C0C0C0", width: "20%" }}>
												<label style={{ position: "relative", fontWeight: "500", color: "#8F91DA" }}>
													{Constants.Common.reimbursement}
												</label>
											</th>
										</tr>
									</thead>
									{
										invoiceListing.filter((conceptListing: string) => editableInvoice[conceptListing]).map
											(
												(conceptListing: string, conceptIndex: number) => <tbody key={"TC_" + conceptIndex}>{
													editableInvoice[conceptListing].map
														(
															(concept: any, index: number) => <tr key={conceptListing + concept.id}>
																<td style={{ width: "1px" }}>
																	<button type="button" disabled={idleLocked || concept.disabled}
																		className="btn btn-default" style={{ padding: "unset", width: "1px" }}
																		onClick={() => toggleReimbursement(concept, index)}
																	>
																		<CustomIcon name={reimbursements["RE_" + concept.id] ? "bCheck" : "bUncheck"} />
																	</button>
																</td>
																<td ref={el => { if (el) el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") }}
																	style={{ verticalAlign: "bottom", width: "20%", padding: "unset" }}
																>
																	<label style={{ position: "relative", wordBreak: "break-all", whiteSpace: "unset" }}>
																		<b style={{ color: (editableInvoice.status_id === invoiceStatus.PARTLY_PAID ? "#C32722" : "#000000") }}>
																			{concept.title}
																		</b>
																	</label>
																	{
																		isEmpty(concept.description) ? "" : <div style={{ wordBreak: "break-all", width: "100%" }}>
																			<label style={{ position: "relative", whiteSpace: "unset" }}>
																				{concept.description}
																			</label>
																		</div>
																	}
																</td>
																<td style={{ borderBottom: "1px solid #C7C7C7", textAlign: "right", verticalAlign: "bottom", padding: "unset" }}>
																	{concept.quantity}
																</td>
																<td style={{ borderBottom: "1px solid #C7C7C7", textAlign: "right", whiteSpace: "nowrap", verticalAlign: "bottom", padding: "unset" }}>
																	{sprintf(currencySymbol + " %.02f", concept.price)}
																</td>
																<td style={{ borderBottom: "1px solid #C7C7C7", textAlign: "right", whiteSpace: "nowrap", verticalAlign: "bottom", padding: "unset" }}>
																	{sprintf(currencySymbol + " %.02f", concept.invoice_item.total)}
																</td>
																<td ref={el => { if (el) el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") }}
																	style={{ borderBottom: "1px solid #C7C7C7", textAlign: "right", whiteSpace: "nowrap", verticalAlign: "bottom", padding: "unset" }}
																>{
																		concept.invoice_item.refunded_amount > 0
																			?
																			concept.invoice_item.refunded_amount
																			:
																			<input onFocus={validateField} min={0}
																				value={reimbursements["RE_" + concept.id]?.amount || 0} type="number"
																				style={{ textAlign: "right", width: "100%" }} placeholder="Monto"
																				disabled={idleLocked || !reimbursements["RE_" + concept.id]}
																				max={+concept.invoice_item.total} onBlur={validateField}
																				id={"RE_" + concept.id} name={"RE_" + concept.id} onChange=
																				{
																					(event: any) => handleReimbursement(event, concept)
																				} required
																			/>
																	}</td>
															</tr>
														)}</tbody>
											)
									}
								</table>
							</form>
						</div>
						<div style={{ textAlign: "right" }}>
							<div style={{ display: "inline-flex" }}>
								<label style={{ position: "relative", padding: "10px" }}>
									{Constants.Common.totalText}
								</label>
								{
									editableInvoice == null || editableInvoice.total == null ? <></> : <div className="inputContainer">
										<h3>
											{currencySymbol}
											&nbsp;
											{sprintf("%.02f", editableInvoice.total)}
										</h3>
									</div>
								}
							</div>
							<br />
							<br />
						</div>
						<br />
						<div className="row">
							<div className="col-sm-6">
								<label style={{ position: "relative" }}>
									{Constants.Common.cancelDetail}
								</label>
								{/* <div style={{wordBreak : "break-word"}}>
							{editableInvoice.notes}
						</div> */}
								{
									<textarea onBlur={validateField} value={editablePayment.notes} id="invoice-item-notes"
										onChange={(event: any) => handleInvoiceItemNotes(event?.target.value)} maxLength={255}
										onFocus={validateField} name="invoice-item-notes" placeholder={Constants.Common.cancelDetail}
										disabled={idleLocked || Object.keys(reimbursements).length < 1} style=
										{{
											resize: "vertical",
											width: "100%",
											border: "1px solid #7678BF",
											borderRadius: "5px",
											minHeight: "150px",
											padding: "5px",
											cursor: idleLocked || Object.keys(reimbursements).length < 1 ? "not-allowed" : "inherit"
										}}
									/>
								}
							</div>
							{
								(selectedInvoice.payments == null || selectedInvoice.payments.length < 1) ? "" : <>
									<UnderlineHeading name={"Pago total"} />
									<div style={{ padding: "30px" }}>
										<table style={{ width: "100%" }}>
											<tbody>
												<tr>
													<td style={{ paddingRight: "15px", width: "100%", textAlign: "right" }}>
														<h3>
															{currencySymbol}
														</h3>
													</td>
													<td style={{ textAlign: "right" }}>
														<h3>
															{sprintf("%.02f", +selectedInvoice.approvedPaidTotal)}
														</h3>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</>
							}
							{
								<button className="btn btn-warning" type="button"
									disabled={idleLocked || Object.keys(reimbursements).length < 1} onClick={confirmRemoval}
								>
									{Constants.Common.process}
								</button>
							}
						</div>
					</>
				}
			</div>
		</Box></div>
}

export default FinancePrompt