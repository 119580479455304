const Constants =
{
	noSearchResults: "No hay resultados que coincidan con los criterios elegidos.",
	noCampusSearchResults: "No hay resultados que coincidan con los criterios elegidos en este plantel.",
	Screens:
	{
		Finances:
		{
			payments: {},
			invoices: { invoice: "Factura" },
			accountStatement:
			{
				header: "Finanzas | Estudiante",
				subtitle: "Finanzas general",
				dueMonthlyBalance: "Saldo mensualidades vencido",
				monthlyBalance: "Saldo mensualidades total",
				kitBalance: "Saldo kits",
				otherBalance: "Saldo otros",
				positiveBalance: "Crédito a favor",
				total: "Total",
				history: "Historial"
			},
			modalShopping:
			{
				warningTextPt1: "Texto alerta mensaje",
				warningTextPt2: "NO SE PERMITEN PAGOS PARCIALES",
				cart: "Mi Carrito"
			},
			modalPayments:
			{
				subtitle: "Nombre del alumno*",
				notes: "Notas",
				fileName: "Nombre-archivo.JPG",
				receiptFile: "Comprobante deposito.PDF",
				downloadFile: "Descargar",
				delete: "Eliminar",
				uploadFile: "Cargar archivo",
				attachFile: "Adjuntar archivos",
				btnPayment: "Registrar pago",
				emitPayment: "Emitir Pago",
				dropzoneText: "Arrastra tu archivo o haz click aquí para subirlo",
				pendingTotal: "Total Pendiente",
				approvedTotal: "Total Aprobado",
				paidTotal: "Total Pagado",
				payment:
				{
					title: "Pago de factura",
					receive: "Importe recibido ",
					date: "Fecha de pago",
					number: "No. de pago",
					method: "Método de pago",
					depositTo: "Depósito para",
					reference: "N° de referencia",
					paymentID: "Hostbill Payment ID"
				},
			},
			modalInvoiceDetail:
			{
				header: "Factura",
				subHeader: "Datos Alumnos",
				names: "Nombre(s)",
				files: "Archivo(s)",
				lastNames: "Apellidos",
				subTotal: "Sub total",
				adjust: "Ajuste",
				total: "Total",
				btnPay: "Pagar",
				btnLoad: "Cargar",
				btnDownload: "Descargar",
				uploadText: "Subir archivo",
				paymentsReceive: "Pagos Recibidos",
				dates:
				{
					title: "Fecha de",
					dueDate: "facturación",
					billingDate: "vencimiento",
				},
			}
		}
	},
	Common:
	{
		id: "id",
		historial: "Historial",
		search: "Búsqueda...",
		invoiceAmount: "Importe",
		expired: "Vencida",
		tax: "%",
		course: "Curso",
		name: "Nombre",
		names: "Nombres",
		lastName: "Apellido",
		lastNames: "Apellidos",
		period: "Período",
		tuition: "Colegiatura",
		correlative: "Correlativo",
		schedule: "Horario",
		level: "Nivel",
		process: "Procesar",
		cancel: "Cancelar",
		close: "Cerrar",
		date: "Fecha",
		endDate: "Fecha venc.",
		balance: "Saldo",
		fare: "Tarifa",
		status: "Estatus",
		billingStatus: "Estatus Financiero",
		progressStatus: "Estatus de Matrícula",
		amount: "Monto",
		totalAmount: "Monto Total",
		paidAmount: "Monto Pagado",
		pendingAmount: "Monto Pendiente",
		concept: "Concepto",
		notes: "Notas",
		cancelDetail: "Motivo de Devolución",
		invoiceDetail: "Detalle Factura",
		paymentDetail: "Detalle De Pago",
		paymentDate: "Fecha De Pago",
		courses: "Courses",
		kits: "Kits",
		others: "Otros",
		price: "Precio",
		totalText: "Total",
		student: "Alumno",
		invoice: "Factura",
		detail: "Detalle",
		quantity: "Cantidad",
		discount: "Descuento",
		totalPaymentText: "Pago Total",
		partialPaymentText: "Pago Parcial",
		generateInvoice: "Generar Factura Pagada",
		generateInvoiceLegend: "El monto total a pagar es 0, por lo que puede emitirse una factura sin necesidad de pago.",
		noPartialPayments: "No se aceptan pagos parciales",
		reference: "Referencia",
		scheduleCode: "Cod. Horario",
		observations: "Observaciones",
		saveChanges: "Guardar cambios",
		invoicePayment: "Pago de factura",
		newInvoicePayment: "Pago de factura nueva",
		optionRequired: "Requerido: Elija una opción",
		gtZeroRequired: "Requerido: Valor debe ser mayor a 0",
		dueDate: "Fecha de Facturación",
		due: "Vencimiento",
		reimbursement: "Devolución",
		units: "Unidades",
		module: "Módulo",
		modules: "Módulos",
		attachments: "Archivos adjuntos",
		paymentReceive: "Importe recibido",
		attachedFiles: "Archivos adjuntos",
		files: "Archivo(s)",
		invoices: "Facturas",
		payments: "Pagos",
		invoiceIsCancel: "CANCELADA",
		downloadProofOfPayment: "Descargar comprobante de Pago",
		isGreaterThan90Days: "La factura tiene más de 90 días (3 meses) de emisión, por lo cuál no es posible Cancelarla",
		invoiceIsNoCancelable: "Para CANCELAR la factura debe CANCELAR las facturas de mensualidades posteriores.",
		paymentIsNoCancelable: "Para CANCELAR el pago debe CANCELAR las facturas de mensualidades posteriores.",
		paymentMethod: "Método de Pago",
		recipientAccount: "Cuenta Receptora",
		createdAt: "Fecha de creación",
		showMore: "Mostrar más",
		showLess: "Mostrar menos",
		goToAccountStatement: "Ir a Estado de Cuenta",
		showDetail: "Ver Detalle",
		updateEnrollment: "Actualizar Matrícula",
		hasPendientTuitions: `El alumno cuenta con mensualidades adeudadas`
	},
	permissions: {
		invoices: {
			cancel: "No cuentas con permisos para Cancelar Facturas."
		},
		payments: {
			cancel: "No cuentas con permisos para Cancelar Pagos."
		}
	},
	Styles:
	{
		text:
		{
			colors:
			{
				gray: "#8F91DA",
				grayOpacity: "#AAB0BB",
				borderGray: "#141535",
				totalGray: "#979797",
				lowGray: "#939393",
				red: "#C32722",
				redLine: "#F13731",
				dark: "#2F316B",
				darkBlue: "#2878A2",
				darkGray: "#5154AE",
				orange: "#FF6600",
				blackPrimary: "#000000",
				white: "#FFFFFF",
				redButtonClose: "#7A1A17",
				veryPeri: "#8F91DA",
				green: "#00FF00"
			},
			fonts:
			{
				robotoNormal10: "normal 10px Roboto",
				robotoNormal12: "normal 12px Roboto",
				robotoNormal14: "normal 14px Roboto",
				robotoNormal16: "normal 16px Roboto",
				robotoNormal37: "normal 37px Roboto",
				robotoNormal20: "normal 20px Roboto",
				robotoMedium14: "medium 14px Roboto",
				robotoMedium16: "medium 16px Roboto",
				robotoMedium20: "medium 20px Roboto",
				robotoBold10: "bold 10px Roboto",
				robotoBold12: "bold 12px Roboto",
				robotoBold14: "bold 14px Roboto",
				robotoBold15: "bold 15px Roboto",
				robotoBold16: "bold 16px Roboto",
				robotoBold20: "bold 20px Roboto",
				robotoBold24: "bold 24px Roboto",
				robotoBold28: "bold 28px Roboto"
			}
		},
		background:
		{
			blackPrimary: "#000000",
			grayOpacity: "#AAB0BB",
			red: "#F13731",
			darkRed: "#8D0606",
			white: "#FFFFFF",
			gray: "#8F91DA",
			lowGray: "#F1F1FF",
			transparent: "#00000000",
			redButtonClose: "#7A1A17"
		}
	},
}

export const articleType: any =
{
	STANDARD_KIT: 1,
	PREMIUM_KIT: 2,
	OTHERS: 3
}

export const paymentStatus = {
	PENDING_APPROVAL: 0,
	APPROVED: 1,
	REJECTED: 2,
	CANCELLED: 3
} as const;

export const owedArticleStatus = {
	PENDING: 0,
	PARTLY_PAID: 1,
	FULLY_PAID: 2,
	DELIVERED: 3
} as const;

export const owedTuitionStatus = {
	PENDING: 0,
	PARTLY_PAID: 1,
	FULLY_PAID: 2,
	OVERDUE: 3
} as const;

export const owedTuitionType = {
	SUBSCRIPTION_FEE: 0,
	MONTHLY_FEE: 1,
	SURCHARGE: 2,
	CANCELLING_FEE: 3
} as const;

export const tunning: any =
{
	MODAL_DISMISS_DELAY: 1500
}

export const assestmentsStatus: any =
{
	INACTIVE: 0,
	ACTIVE: 1
}

export const assestmentsTypes: any =
{
	PARTIAL: 1,
	FINAL: 2,
	EXTRAORDINARY: 3
}

export const assigmentStatus: any =
{
	INACTIVE: 0,
	ACTIVE: 1
}

export const attendanceStatus: any =
{
	ABSENT: 0,
	ONTIME: 1,
	DELAY: 2,
	COMPENSATION: 2
}

export const academicDegree: any =
{
	NONE: 0,
	PRESCHOOL: 1,
	ELEMENTARY_SCHOOL: 2,
	JUNIOR_HIGH_SCHOOL: 3,
	HIGH_SCHOOL: 4,
	UNIVERSITY_INTERN: 5,
	UNIVERSITY: 6,
	MASTER: 7,
	DOCTORATE: 8,
}

export const country: any =
{
	MEXICO: 1,
	HONDURAS: 2,
	BRAZIL: 3
}

export const commonStatus: any =
{
	DISABLED: 0,
	ENABLED: 1
}

export const campusStatus: any =
{
	INACTIVE: 0,
	ACTIVE: 1
}

export const classroomStatus: any =
{
	INACTIVE: 0,
	ACTIVE: 1
}

export const articleStatus: any =
{
	UNAVAILABLE: 0,
	AVAILABLE: 1
}

export const assignmentStatus: any =
{
	INACTIVE: 0,
	ACTIVE: 1
}

export const courseStatus: any =
{
	INACTIVE: 0,
	ACTIVE: 1
}

export const courseLevelStatus: any =
{
	INACTIVE: 0,
	ACTIVE: 1
}

export const scheduleStatus: any =
{
	INACTIVE: 0,
	ACTIVE: 1
}

export const employeeStatus: any =
{
	DISABLED: 0,
	ACTIVE: 1,
	FIRED: 2,
	VACATIONS: 3,
	SICK: 4,
	PERMIT: 5
}

export const studentStatus: any =
{
	DISABLED: 0,
	ACTIVE: 1,
	RETIRED: 2,
	PAUSED: 3,
	GRADUATED: 4
}

export const invoiceStatus =
{
	PENDING: 0,
	PARTLY_PAID: 1,
	FULLY_PAID: 2,
	CANCELLED: 3
} as const;

export const weekDay: any =
{
	MONDAY: 0,
	TUESDAY: 1,
	WENDNESDAY: 2,
	THURSDAY: 3,
	FRIDAY: 4,
	SATURDAY: 5,
	SUNDAY: 6,
}

export const mgmtLimit: any =
{
	NONE: 0,
	DISPLAY_ONLY: 1,
	DISPLAY_AND_UPDATE: 2
}

export const grant: any =
{
	READ: 0,
	CREATE: 1,
	UPDATE: 2,
	DELETE: 3,
	NAVIGATE: 4
}

export const FINANCES_MODALS = {
	CHECKOUT: 1,
	INVOICE: 2,
	GENERATE_INVOICE: 3,
	CANCEL_INVOICE: 4,
	PAYMENT_DETAIL: 5,
	EMIT_PAYMENT: 6,
	CANCEL_PAYMENT: 7
} as const;

export const TYPE_OF_DISCOUNT: any = {
	NONE: 0,
	NUMERIC: 1,
	PERCENTAGE: 2,
}

export const CATALOG = {
	CURRENCIES: "CURRENCIES",
	COUNTRIES: "COUNTRIES",
	PAYMENT_STATUS: "PAYMENT_STATUS",
	ENROLLMENT_STATUS: "ENROLLMENT_STATUS"
} as const;

export const FILTER_TYPES = {
	NONE: 0,
	INPUT: 1,
	DROPDOWN: 2,
	MULTISELECT: 3,
} as const;

export const TUITION_PROGRESS_STATUS = {
	ACTIVE: 1,
	NO_COMPLETED: 2,
	COMPLETED: 3
} as const;

export const ENROLLMENT_STATUS = {
	INACTIVE: 0,
	ACTIVE: 1,
	COMPLETED: 2,
	ERROR: 3,
	NOT_STARTED: 4,
	CHANGE: 5,
	RETIRED: 6,
	POSTPONED: 7,
	SPECIALIZATION: 8
} as const;

export const PERMISSIONS_CODES = {
	INVOICE_PERMISSIONS: {
		DELETE: 1000,
		GET: 1001,
		CREATE: 1002,
		UPDATE: 1003
	},
	PAYMENT_PERMISSIONS: {
		DELETE: 1004,
		GET: 1005,
		CREATE: 1006,
		UPDATE: 1007
	}
} as const;

export default Constants;